import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: { input: any; output: any };
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: { input: any; output: any };
  /**
   * The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library. The Decimal appears in a JSON
   * response as a string to preserve precision.
   */
  Decimal: { input: string; output: string };
};

export type AddressDetails = {
  __typename?: "AddressDetails";
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  houseNumber: Scalars["String"]["output"];
  postalCode: Scalars["String"]["output"];
  street: Scalars["String"]["output"];
};

export type AddressInput = {
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  houseNumber: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  street: Scalars["String"]["input"];
};

export type BankDetails = {
  __typename?: "BankDetails";
  accountOwner: Scalars["String"]["output"];
  bic?: Maybe<Scalars["String"]["output"]>;
  iban: Scalars["String"]["output"];
};

export type CreateSupportCaseInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  customerId: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  subject: Scalars["String"]["input"];
};

export enum CreateSupportCaseMutationError {
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type CreateSupportCaseMutationResult = {
  __typename?: "CreateSupportCaseMutationResult";
  caseNumber?: Maybe<Scalars["String"]["output"]>;
  errors: Array<CreateSupportCaseMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum CurrentUserQueryError {
  UserNotLoggedIn = "USER_NOT_LOGGED_IN",
}

export type CurrentUserQueryResult = {
  __typename?: "CurrentUserQueryResult";
  errors: Array<CurrentUserQueryError>;
  success: Scalars["Boolean"]["output"];
  user?: Maybe<User>;
};

export type Customer = {
  __typename?: "Customer";
  /** User visible customer number (coming from SAP) */
  customerNumber?: Maybe<Scalars["String"]["output"]>;
  /** Salesforce internal ID of the customer (Account ID) */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  name2?: Maybe<Scalars["String"]["output"]>;
  serviceSubscriptions: Array<ServiceSubscription>;
};

export type CustomerManagementCustomer = {
  __typename?: "CustomerManagementCustomer";
  address?: Maybe<CustomerManagementCustomerAddress>;
  /** User visible customer number (coming from SAP) */
  customerNumber?: Maybe<Scalars["String"]["output"]>;
  /** Salesforce internal ID of the customer (Account ID) */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  name2?: Maybe<Scalars["String"]["output"]>;
  serviceSubscriptions: Array<CustomerManagementService>;
};

export type CustomerManagementCustomerAddress = {
  __typename?: "CustomerManagementCustomerAddress";
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  /** Street name including house number */
  street?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerManagementService = {
  __typename?: "CustomerManagementService";
  id: ServiceSubscription;
  name: Scalars["String"]["output"];
  serviceRoles: Array<CustomerManagementServiceRole>;
};

export type CustomerManagementServiceRole = {
  __typename?: "CustomerManagementServiceRole";
  id: ServiceRole;
  name: Scalars["String"]["output"];
};

export enum CustomerManagementUpdateUserPermissionsError {
  CannotRemoveOwnAccountManagerPermissions = "CANNOT_REMOVE_OWN_ACCOUNT_MANAGER_PERMISSIONS",
  InternalError = "INTERNAL_ERROR",
  Unauthorized = "UNAUTHORIZED",
}

export type CustomerManagementUpdateUserPermissionsResult = {
  __typename?: "CustomerManagementUpdateUserPermissionsResult";
  errors: Array<CustomerManagementUpdateUserPermissionsError>;
  success: Scalars["Boolean"]["output"];
};

export type CustomerManagementUserData = {
  __typename?: "CustomerManagementUserData";
  createdAt: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  registrationLink?: Maybe<Scalars["String"]["output"]>;
  salutation?: Maybe<Salutation>;
  servicesWithRoles: Array<CustomerManagementService>;
  state: CustomerManagementUserState;
};

export type CustomerManagementUserDataFilterInput = {
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  services?: InputMaybe<Array<ServiceSubscription>>;
  states?: InputMaybe<Array<CustomerManagementUserState>>;
};

export type CustomerManagementUserDataPage = {
  __typename?: "CustomerManagementUserDataPage";
  paginationMetadata: PaginationMetadata;
  userData: Array<CustomerManagementUserData>;
};

export enum CustomerManagementUserState {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Invited = "INVITED",
  Unknown = "UNKNOWN",
}

export enum ExistingRegistrationSalutationInput {
  Mr = "MR",
  Mrs = "MRS",
}

export enum FleetAtHomeAcceptProposedElectricityPriceMutationError {
  InternalError = "INTERNAL_ERROR",
  NoProposedElectricityPrice = "NO_PROPOSED_ELECTRICITY_PRICE",
  Unauthorized = "UNAUTHORIZED",
}

export type FleetAtHomeAcceptProposedElectricityPriceMutationResult = {
  __typename?: "FleetAtHomeAcceptProposedElectricityPriceMutationResult";
  errors: Array<FleetAtHomeAcceptProposedElectricityPriceMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum FleetAtHomeAssignWscToUserMutationError {
  InternalError = "INTERNAL_ERROR",
  Unauthorized = "UNAUTHORIZED",
  WscAlreadyAssigned = "WSC_ALREADY_ASSIGNED",
}

export type FleetAtHomeAssignWscToUserMutationResult = {
  __typename?: "FleetAtHomeAssignWscToUserMutationResult";
  errors: Array<FleetAtHomeAssignWscToUserMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type FleetAtHomeCreditNote = {
  __typename?: "FleetAtHomeCreditNote";
  dateFrom: Scalars["Date"]["output"];
  dateTo: Scalars["Date"]["output"];
  energyAmountKilowatthours: Scalars["Decimal"]["output"];
  forecasted: Scalars["Boolean"]["output"];
  grossAmountInEuro?: Maybe<Scalars["Decimal"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  numberOfTransactions: Scalars["Int"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export enum FleetAtHomeDeclineProposedElectricityPriceMutationError {
  InternalError = "INTERNAL_ERROR",
  NoProposedElectricityPrice = "NO_PROPOSED_ELECTRICITY_PRICE",
  Unauthorized = "UNAUTHORIZED",
}

export type FleetAtHomeDeclineProposedElectricityPriceMutationResult = {
  __typename?: "FleetAtHomeDeclineProposedElectricityPriceMutationResult";
  errors: Array<FleetAtHomeDeclineProposedElectricityPriceMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type FleetAtHomeElectricityPrice = {
  __typename?: "FleetAtHomeElectricityPrice";
  createdAt: Scalars["DateTime"]["output"];
  dateFrom: Scalars["Date"]["output"];
  dateTo?: Maybe<Scalars["Date"]["output"]>;
  electricityPricePerKilowattHour: Scalars["Decimal"]["output"];
  id: Scalars["String"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  state: FleetAtHomeElectricityPriceState;
  url?: Maybe<Scalars["String"]["output"]>;
};

export enum FleetAtHomeElectricityPriceState {
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Proposed = "PROPOSED",
  Withdrawn = "WITHDRAWN",
}

export type FleetAtHomeOrder = {
  __typename?: "FleetAtHomeOrder";
  id: Scalars["ID"]["output"];
  invoiceUrl: Scalars["String"]["output"];
  netAmount: Scalars["Decimal"]["output"];
  state: Scalars["String"]["output"];
  totalAmount: Scalars["Decimal"]["output"];
  trackingNumber?: Maybe<Scalars["String"]["output"]>;
  vatAmount: Scalars["Decimal"]["output"];
};

export type FleetAtHomeOrderAddressInput = {
  addition?: InputMaybe<Scalars["String"]["input"]>;
  city: Scalars["String"]["input"];
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  country: Scalars["String"]["input"];
  department?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  houseNumber: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  salutation: Salutation;
  street: Scalars["String"]["input"];
};

export type FleetAtHomeOrderInput = {
  billingAddress: FleetAtHomeOrderAddressInput;
  items: Array<FleetAtHomeOrderItemInput2>;
  shippingAddress: FleetAtHomeOrderAddressInput;
  totalAmount: Scalars["Decimal"]["input"];
};

export type FleetAtHomeOrderItemInput2 = {
  id: Scalars["ID"]["input"];
  quantity: Scalars["Decimal"]["input"];
};

export type FleetAtHomeOrderPreview = {
  __typename?: "FleetAtHomeOrderPreview";
  items: Array<FleetAtHomeOrderPreviewItem>;
  netAmount: Scalars["Decimal"]["output"];
  shipping: Scalars["Decimal"]["output"];
  totalAmount: Scalars["Decimal"]["output"];
  vatAmount: Scalars["Decimal"]["output"];
};

export type FleetAtHomeOrderPreviewDataInput = {
  product: FleetAtHomeShopProductInput;
};

export type FleetAtHomeOrderPreviewItem = {
  __typename?: "FleetAtHomeOrderPreviewItem";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["Decimal"]["output"];
  quantity: Scalars["Decimal"]["output"];
};

export enum FleetAtHomePlaceOrderMutationError {
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  PricesChanged = "PRICES_CHANGED",
  Unauthorized = "UNAUTHORIZED",
}

export type FleetAtHomePlaceOrderMutationResult = {
  __typename?: "FleetAtHomePlaceOrderMutationResult";
  errors: Array<FleetAtHomePlaceOrderMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum FleetAtHomeProposeElectricityPriceMutationError {
  ExistingProposedPrice = "EXISTING_PROPOSED_PRICE",
  InternalError = "INTERNAL_ERROR",
  Unauthorized = "UNAUTHORIZED",
}

export type FleetAtHomeProposeElectricityPriceMutationResult = {
  __typename?: "FleetAtHomeProposeElectricityPriceMutationResult";
  errors: Array<FleetAtHomeProposeElectricityPriceMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type FleetAtHomeRegistrationDataInput = {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  newsletterConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  password: Scalars["String"]["input"];
  provisioningKey: Scalars["String"]["input"];
  salutation: RegistrationSalutationInput;
};

export enum FleetAtHomeReleaseWscFromUserMutationError {
  InternalError = "INTERNAL_ERROR",
  NotFound = "NOT_FOUND",
  NoWscAssigned = "NO_WSC_ASSIGNED",
  Unauthorized = "UNAUTHORIZED",
}

export type FleetAtHomeReleaseWscFromUserMutationResult = {
  __typename?: "FleetAtHomeReleaseWscFromUserMutationResult";
  errors: Array<FleetAtHomeReleaseWscFromUserMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type FleetAtHomeShopProduct = {
  __typename?: "FleetAtHomeShopProduct";
  accessories: Array<FleetAtHomeShopProductAccessory>;
  category: Scalars["String"]["output"];
  configurationOptions: Array<FleetAtHomeShopProductAccessory>;
  currency: Scalars["String"]["output"];
  detailedDescription: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  image?: Maybe<FleetAtHomeShopProductImage>;
  listPrice: Scalars["Decimal"]["output"];
  name: Scalars["String"]["output"];
  obligatoryConfigurationOptions: Array<FleetAtHomeShopProductAccessory>;
  price: Scalars["Decimal"]["output"];
  shortDescription: Scalars["String"]["output"];
};

export type FleetAtHomeShopProductAccessory = {
  __typename?: "FleetAtHomeShopProductAccessory";
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  listPrice: Scalars["Decimal"]["output"];
  price: Scalars["Decimal"]["output"];
  shortDescription: Scalars["String"]["output"];
};

export type FleetAtHomeShopProductAccessoryInput = {
  id: Scalars["ID"]["input"];
};

export type FleetAtHomeShopProductImage = {
  __typename?: "FleetAtHomeShopProductImage";
  content: Scalars["String"]["output"];
  mimeType: Scalars["String"]["output"];
};

export type FleetAtHomeShopProductInput = {
  accessories: Array<FleetAtHomeShopProductAccessoryInput>;
  configurationOptions: Array<FleetAtHomeShopProductAccessoryInput>;
  id: Scalars["ID"]["input"];
  obligatoryConfigurationOptions: Array<FleetAtHomeShopProductAccessoryInput>;
};

export type FleetAtHomeStation = {
  __typename?: "FleetAtHomeStation";
  alias?: Maybe<Scalars["String"]["output"]>;
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  currentElectricityPrice?: Maybe<FleetAtHomeElectricityPrice>;
  earliestPossibleElectricityPriceProposal: Scalars["Date"]["output"];
  houseNumber: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  order?: Maybe<FleetAtHomeOrder>;
  postalCode: Scalars["String"]["output"];
  proposedElectricityPrice?: Maybe<FleetAtHomeElectricityPrice>;
  provider: Scalars["String"]["output"];
  setupNecessary: Scalars["Boolean"]["output"];
  state: FleetAtHomeStationState;
  street: Scalars["String"]["output"];
};

export enum FleetAtHomeStationState {
  Active = "ACTIVE",
  Deactivated = "DEACTIVATED",
  OrderReceived = "ORDER_RECEIVED",
}

export type FleetAtHomeTransaction = {
  __typename?: "FleetAtHomeTransaction";
  amount?: Maybe<Scalars["Decimal"]["output"]>;
  creditState: FleetAtHomeTransactionCreditState;
  energyAmountKilowatthours?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
  startTime: Scalars["DateTime"]["output"];
  station: FleetAtHomeStation;
  stopTime?: Maybe<Scalars["DateTime"]["output"]>;
  user: User;
};

export enum FleetAtHomeTransactionCreditState {
  Credited = "CREDITED",
  Open = "OPEN",
  PriceMissing = "PRICE_MISSING",
  PriceProposed = "PRICE_PROPOSED",
}

export enum FleetAtHomeUpdateStationMutationError {
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type FleetAtHomeUpdateStationMutationResult = {
  __typename?: "FleetAtHomeUpdateStationMutationResult";
  errors: Array<FleetAtHomeUpdateStationMutationError>;
  station?: Maybe<FleetAtHomeStation>;
  success: Scalars["Boolean"]["output"];
};

export type FleetAtHomeUserData = {
  __typename?: "FleetAtHomeUserData";
  addressDetails?: Maybe<AddressDetails>;
  createdAt: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  registrationLink?: Maybe<Scalars["String"]["output"]>;
  salutation?: Maybe<Salutation>;
  state: FleetAtHomeUserState;
  stations: Array<FleetAtHomeStation>;
  wscNumber?: Maybe<Scalars["String"]["output"]>;
};

export enum FleetAtHomeUserState {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Invited = "INVITED",
  Unknown = "UNKNOWN",
}

export type FleetAtHomeWsc = {
  __typename?: "FleetAtHomeWsc";
  cardNr?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type InvitationDataInput = {
  customerId: Scalars["ID"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  salutation?: InputMaybe<RegistrationSalutationInput>;
};

export enum InvitationResult {
  UserExisted = "USER_EXISTED",
  UserInvited = "USER_INVITED",
}

export enum InviteUserMutationError {
  ContactAlreadyExists = "CONTACT_ALREADY_EXISTS",
  InternalError = "INTERNAL_ERROR",
  MultipleContactsFound = "MULTIPLE_CONTACTS_FOUND",
  Unauthorized = "UNAUTHORIZED",
  UserDataDiffers = "USER_DATA_DIFFERS",
}

export type InviteUserMutationResult = {
  __typename?: "InviteUserMutationResult";
  caseNumber?: Maybe<Scalars["String"]["output"]>;
  errors: Array<InviteUserMutationError>;
  invitationResult?: Maybe<InvitationResult>;
  success: Scalars["Boolean"]["output"];
};

export type Maintenance = {
  __typename?: "Maintenance";
  dateFrom: Scalars["DateTime"]["output"];
  dateTo?: Maybe<Scalars["DateTime"]["output"]>;
};

export enum MutationError {
  InternalError = "INTERNAL_ERROR",
  Unauthorized = "UNAUTHORIZED",
}

export type MutationResult = {
  __typename?: "MutationResult";
  errors: Array<MutationError>;
  success: Scalars["Boolean"]["output"];
};

export type Notification = {
  __typename?: "Notification";
  dateFrom: Scalars["DateTime"]["output"];
  dateTo?: Maybe<Scalars["DateTime"]["output"]>;
  infoText?: Maybe<Scalars["String"]["output"]>;
  type: NotificationType;
};

export enum NotificationType {
  Announcement = "ANNOUNCEMENT",
  Maintenance = "MAINTENANCE",
}

export type PaginationInput = {
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
};

export type PaginationMetadata = {
  __typename?: "PaginationMetadata";
  page: Scalars["Int"]["output"];
  pageSize: Scalars["Int"]["output"];
  totalEntries: Scalars["Int"]["output"];
  totalPages: Scalars["Int"]["output"];
};

export type Permissions = {
  __typename?: "Permissions";
  customers: Array<Customer>;
  fleetAtHomeManager: Scalars["Boolean"]["output"];
  fleetAtHomeManagerCustomerIds: Array<Scalars["ID"]["output"]>;
  fleetAtHomeUser: Scalars["Boolean"]["output"];
  fleetAtHomeUserCustomerId?: Maybe<Scalars["ID"]["output"]>;
  gaasUser: Scalars["Boolean"]["output"];
  gaasUserCustomerIds: Array<Scalars["ID"]["output"]>;
  thgQuoteManager: Scalars["Boolean"]["output"];
  thgQuoteManagerCustomerIds: Array<Scalars["ID"]["output"]>;
  virtaUser: Scalars["Boolean"]["output"];
  virtaUserCustomerIds: Array<Scalars["ID"]["output"]>;
  wccManager: Scalars["Boolean"]["output"];
  wccManagerCustomerIds: Array<Scalars["ID"]["output"]>;
  westfalenIdAccountManager: Scalars["Boolean"]["output"];
  westfalenIdAccountManagerCustomerIds: Array<Scalars["ID"]["output"]>;
  wscManager: Scalars["Boolean"]["output"];
  wscManagerCustomerIds: Array<Scalars["ID"]["output"]>;
};

export type RegisterExistingCustomerInput = {
  captcha: Scalars["String"]["input"];
  customerId: Scalars["ID"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  salutation: ExistingRegistrationSalutationInput;
};

export enum RegisterExistingCustomerMutationError {
  CaptchaValidationFailed = "CAPTCHA_VALIDATION_FAILED",
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type RegisterExistingCustomerMutationResult = {
  __typename?: "RegisterExistingCustomerMutationResult";
  caseNumber?: Maybe<Scalars["String"]["output"]>;
  errors: Array<RegisterExistingCustomerMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum RegisterFleetAtHomeUserMutationError {
  InternalError = "INTERNAL_ERROR",
  NoValidProvisioningKey = "NO_VALID_PROVISIONING_KEY",
  Unauthorized = "UNAUTHORIZED",
  UserAlreadyRegistered = "USER_ALREADY_REGISTERED",
}

export type RegisterFleetAtHomeUserMutationResult = {
  __typename?: "RegisterFleetAtHomeUserMutationResult";
  errors: Array<RegisterFleetAtHomeUserMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum RegisterUserMutationError {
  CaptchaValidationFailed = "CAPTCHA_VALIDATION_FAILED",
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type RegisterUserMutationResult = {
  __typename?: "RegisterUserMutationResult";
  errors: Array<RegisterUserMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type RegistrationData = {
  __typename?: "RegistrationData";
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  salutation?: Maybe<Salutation>;
};

export enum RegistrationDataError {
  InternalError = "INTERNAL_ERROR",
  NoValidProvisioningKey = "NO_VALID_PROVISIONING_KEY",
  UserAlreadyRegistered = "USER_ALREADY_REGISTERED",
}

export type RegistrationDataInput = {
  captcha: Scalars["String"]["input"];
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  newsletterConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  password: Scalars["String"]["input"];
  salutation: RegistrationSalutationInput;
};

export type RegistrationDataResult = {
  __typename?: "RegistrationDataResult";
  errors: Array<RegistrationDataError>;
  registrationData?: Maybe<RegistrationData>;
  success: Scalars["Boolean"]["output"];
};

export enum RegistrationSalutationInput {
  Mr = "MR",
  Mrs = "MRS",
  NonBinary = "NON_BINARY",
}

export type RootMutationType = {
  __typename?: "RootMutationType";
  createSupportCase: CreateSupportCaseMutationResult;
  customerManagementInviteUser: InviteUserMutationResult;
  customerManagementUpdateUserPermissions: CustomerManagementUpdateUserPermissionsResult;
  deleteMyUser: MutationResult;
  finishRegistration: MutationResult;
  fleetAtHomeAcceptProposedElectricityPrice: FleetAtHomeAcceptProposedElectricityPriceMutationResult;
  fleetAtHomeAssignWscToUser: FleetAtHomeAssignWscToUserMutationResult;
  fleetAtHomeDeclineProposedElectricityPrice: FleetAtHomeDeclineProposedElectricityPriceMutationResult;
  fleetAtHomeInviteUser: InviteUserMutationResult;
  fleetAtHomePlaceOrder: FleetAtHomePlaceOrderMutationResult;
  fleetAtHomeProposeElectricityPrice: FleetAtHomeProposeElectricityPriceMutationResult;
  fleetAtHomeReleaseWscFromUser: FleetAtHomeReleaseWscFromUserMutationResult;
  fleetAtHomeUpdateStation: FleetAtHomeUpdateStationMutationResult;
  fleetAtHomeWithdrawProposedElectricityPrice: MutationResult;
  registerExistingCustomer: RegisterExistingCustomerMutationResult;
  registerFleetAtHomeUser: RegisterFleetAtHomeUserMutationResult;
  registerUser: RegisterUserMutationResult;
  thgQuoteDeletePendingVehicleRegistration: ThgQuoteDeletePendingVehicleRegistrationMutationResult;
  thgQuotePreregisterVehicle: ThgQuotePendingVehicleRegistrationMutationResult;
  thgQuoteRegisterVehicle: ThgQuoteVehicleRegistrationMutationResult;
  thgQuoteResubmitVehicleRegistrations: ThgQuoteResubmitVehicleRegistrationMutationResult;
  updateBankDetails: MutationResult;
  updateEmail: MutationResult;
  updatePassword: MutationResult;
  updateUserData: MutationResult;
  verifyEmail: VerifyEmailMutationResult;
  wccActivateCardSubscriptions: WccCardMutationResult;
  wccCancelCardCharges: WccCardMutationResult;
  wccChargeCards: WccCardMutationResult;
  wccDeactivateCardSubscriptions: WccCardMutationResult;
  wccDeactivateCards: WccCardMutationResult;
  wccPlaceCardOrder: WccPlaceCardOrderMutationResult;
  wccReactivateCards: WccCardMutationResult;
  wccUpdateCardLabels: WccCardMutationResult;
  wccUpdateCardSubscriptionAmounts: WccCardMutationResult;
  wscDeactivateCard: WscMutationResult;
  wscPlaceCardOrder: WscCardMutationResult;
  wscPlaceReplacementCardOrder: WscMutationResult;
  wscRevokeFutureCardDeactivation: WscMutationResult;
  wscSendVirtualCardActivationLink: WscSendVirtualCardActivationLinkMutationResult;
  wscUpdateCard: WscCardMutationResult;
};

export type RootMutationTypeCreateSupportCaseArgs = {
  data: CreateSupportCaseInput;
};

export type RootMutationTypeCustomerManagementInviteUserArgs = {
  invitationData: InvitationDataInput;
  serviceRoles: Array<ServiceRole>;
};

export type RootMutationTypeCustomerManagementUpdateUserPermissionsArgs = {
  customerId: Scalars["ID"]["input"];
  serviceRoles: Array<ServiceRole>;
  userId: Scalars["ID"]["input"];
};

export type RootMutationTypeFinishRegistrationArgs = {
  address: AddressInput;
};

export type RootMutationTypeFleetAtHomeAcceptProposedElectricityPriceArgs = {
  stationId: Scalars["ID"]["input"];
};

export type RootMutationTypeFleetAtHomeAssignWscToUserArgs = {
  cardNr: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type RootMutationTypeFleetAtHomeDeclineProposedElectricityPriceArgs = {
  reason?: InputMaybe<Scalars["String"]["input"]>;
  stationId: Scalars["ID"]["input"];
};

export type RootMutationTypeFleetAtHomeInviteUserArgs = {
  invitationData: InvitationDataInput;
};

export type RootMutationTypeFleetAtHomePlaceOrderArgs = {
  data: FleetAtHomeOrderInput;
};

export type RootMutationTypeFleetAtHomeProposeElectricityPriceArgs = {
  dateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  electricityPriceCertificateId?: InputMaybe<Scalars["ID"]["input"]>;
  electricityPricePerKilowattHour: Scalars["Decimal"]["input"];
  stationId: Scalars["ID"]["input"];
};

export type RootMutationTypeFleetAtHomeReleaseWscFromUserArgs = {
  customerId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type RootMutationTypeFleetAtHomeUpdateStationArgs = {
  alias: Scalars["String"]["input"];
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  houseNumber?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  stationId: Scalars["ID"]["input"];
  street?: InputMaybe<Scalars["String"]["input"]>;
};

export type RootMutationTypeFleetAtHomeWithdrawProposedElectricityPriceArgs = {
  stationId: Scalars["ID"]["input"];
};

export type RootMutationTypeRegisterExistingCustomerArgs = {
  data: RegisterExistingCustomerInput;
};

export type RootMutationTypeRegisterFleetAtHomeUserArgs = {
  registrationData: FleetAtHomeRegistrationDataInput;
};

export type RootMutationTypeRegisterUserArgs = {
  registrationData: RegistrationDataInput;
};

export type RootMutationTypeThgQuoteDeletePendingVehicleRegistrationArgs = {
  id: Scalars["ID"]["input"];
};

export type RootMutationTypeThgQuotePreregisterVehicleArgs = {
  data: ThgQuotePendingVehicleRegistrationInput;
};

export type RootMutationTypeThgQuoteRegisterVehicleArgs = {
  data: ThgQuoteVehicleRegistrationInput;
};

export type RootMutationTypeThgQuoteResubmitVehicleRegistrationsArgs = {
  customerId: Scalars["ID"]["input"];
  ids: Array<Scalars["ID"]["input"]>;
};

export type RootMutationTypeUpdateBankDetailsArgs = {
  accountOwner: Scalars["String"]["input"];
  bic?: InputMaybe<Scalars["String"]["input"]>;
  iban: Scalars["String"]["input"];
};

export type RootMutationTypeUpdateEmailArgs = {
  email: Scalars["String"]["input"];
};

export type RootMutationTypeUpdatePasswordArgs = {
  password: Scalars["String"]["input"];
};

export type RootMutationTypeUpdateUserDataArgs = {
  userData: UserDataInput;
};

export type RootMutationTypeVerifyEmailArgs = {
  email: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type RootMutationTypeWccActivateCardSubscriptionsArgs = {
  cardSubscriptions: Array<WccCardSubscriptionInput>;
  customerId: Scalars["ID"]["input"];
};

export type RootMutationTypeWccCancelCardChargesArgs = {
  cardNumbers: Array<Scalars["String"]["input"]>;
  customerId: Scalars["ID"]["input"];
};

export type RootMutationTypeWccChargeCardsArgs = {
  cardCharges: Array<WccCardChargeInput>;
  customerId: Scalars["ID"]["input"];
};

export type RootMutationTypeWccDeactivateCardSubscriptionsArgs = {
  cardNumbers: Array<Scalars["String"]["input"]>;
  customerId: Scalars["ID"]["input"];
};

export type RootMutationTypeWccDeactivateCardsArgs = {
  cardNumbers: Array<Scalars["String"]["input"]>;
  customerId: Scalars["ID"]["input"];
};

export type RootMutationTypeWccPlaceCardOrderArgs = {
  orderData: WccOrderDataInput;
};

export type RootMutationTypeWccReactivateCardsArgs = {
  cardNumbers: Array<Scalars["String"]["input"]>;
  customerId: Scalars["ID"]["input"];
};

export type RootMutationTypeWccUpdateCardLabelsArgs = {
  cardLabels: Array<WccCardLabelInput>;
  customerId: Scalars["ID"]["input"];
};

export type RootMutationTypeWccUpdateCardSubscriptionAmountsArgs = {
  cardSubscriptions: Array<WccCardSubscriptionInput>;
  customerId: Scalars["ID"]["input"];
};

export type RootMutationTypeWscDeactivateCardArgs = {
  cardNr: Scalars["ID"]["input"];
  futureBlockDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type RootMutationTypeWscPlaceCardOrderArgs = {
  orderData: WscOrderDataInput;
};

export type RootMutationTypeWscPlaceReplacementCardOrderArgs = {
  cardNr: Scalars["ID"]["input"];
  replacementCardOrderData: WscReplacementCardOrderDataInput;
};

export type RootMutationTypeWscRevokeFutureCardDeactivationArgs = {
  cardNr: Scalars["ID"]["input"];
};

export type RootMutationTypeWscSendVirtualCardActivationLinkArgs = {
  cardNr: Scalars["ID"]["input"];
};

export type RootMutationTypeWscUpdateCardArgs = {
  cardNr: Scalars["ID"]["input"];
  updateCardData: WscCardUpdateInput;
};

export type RootQueryType = {
  __typename?: "RootQueryType";
  activeMaintenance?: Maybe<Maintenance>;
  activeNotification?: Maybe<Notification>;
  currentUserV2: CurrentUserQueryResult;
  customerManagementCustomer: CustomerManagementCustomer;
  customerManagementUserDataForCustomer: CustomerManagementUserDataPage;
  fleetAtHomeCreditNotes: Array<FleetAtHomeCreditNote>;
  fleetAtHomeElectricityPriceHistoryForStation: Array<FleetAtHomeElectricityPrice>;
  fleetAtHomeForecastedCreditNotes: Array<FleetAtHomeCreditNote>;
  fleetAtHomeFreeWscsForCustomer: Array<FleetAtHomeWsc>;
  fleetAtHomeOrderPreview: FleetAtHomeOrderPreview;
  fleetAtHomeShopProduct: FleetAtHomeShopProduct;
  fleetAtHomeShopProductCategories: Array<Scalars["String"]["output"]>;
  fleetAtHomeShopProducts: Array<FleetAtHomeShopProduct>;
  fleetAtHomeStation?: Maybe<FleetAtHomeStation>;
  fleetAtHomeStations: Array<FleetAtHomeStation>;
  fleetAtHomeTransactions: Array<FleetAtHomeTransaction>;
  fleetAtHomeUserDataForCustomer: Array<FleetAtHomeUserData>;
  registrationData?: Maybe<RegistrationDataResult>;
  supportCaseCategorySelectOptions: Array<SelectOption>;
  thgQuotePendingVehicleRegistration?: Maybe<ThgQuotePendingVehicleRegistration>;
  thgQuoteVehicleClasses: Array<ThgQuoteVehicleClassInfo>;
  thgQuoteVehicleRegistrationInfo: ThgQuoteVehicleRegistrationInfo;
  thgQuoteVehicleRegistrationsForCustomer: Array<ThgQuoteVehicleRegistration>;
  wccCardsForCustomer: WccCardPage;
  wccChargeHistoryForCard: WccChargePage;
  wccChargeHistoryForCustomer: WccChargesForCustomerPage;
  wccInvoice: WccInvoice;
  wccInvoicesForCustomer: WccInvoicePage;
  wccOrdersForCustomer: WccOrderPage;
  wscCard: WscCard;
  wscCardsForCustomer: WscCardPage;
  wscCustomer: WscCustomer;
  wscGoodsGroups: Array<Scalars["String"]["output"]>;
  wscInvoice: WscInvoice;
  wscInvoicesForCustomer: WscInvoicePage;
  wscStations: Array<WscStation>;
  wscTransaction: WscTransaction;
  wscTransactionsForCustomer: WscTransactionPage;
};

export type RootQueryTypeCustomerManagementCustomerArgs = {
  customerId: Scalars["ID"]["input"];
};

export type RootQueryTypeCustomerManagementUserDataForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  filterParams?: InputMaybe<CustomerManagementUserDataFilterInput>;
  paginationParams: PaginationInput;
};

export type RootQueryTypeFleetAtHomeElectricityPriceHistoryForStationArgs = {
  id: Scalars["ID"]["input"];
};

export type RootQueryTypeFleetAtHomeFreeWscsForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
};

export type RootQueryTypeFleetAtHomeOrderPreviewArgs = {
  data: FleetAtHomeOrderPreviewDataInput;
};

export type RootQueryTypeFleetAtHomeShopProductArgs = {
  id: Scalars["ID"]["input"];
};

export type RootQueryTypeFleetAtHomeStationArgs = {
  id: Scalars["ID"]["input"];
};

export type RootQueryTypeFleetAtHomeUserDataForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
};

export type RootQueryTypeRegistrationDataArgs = {
  provisioningKey: Scalars["String"]["input"];
};

export type RootQueryTypeThgQuotePendingVehicleRegistrationArgs = {
  id: Scalars["ID"]["input"];
};

export type RootQueryTypeThgQuoteVehicleRegistrationInfoArgs = {
  customerId: Scalars["ID"]["input"];
};

export type RootQueryTypeThgQuoteVehicleRegistrationsForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
};

export type RootQueryTypeWccCardsForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  filterParams?: InputMaybe<WccCardFilterInput>;
  paginationParams: PaginationInput;
};

export type RootQueryTypeWccChargeHistoryForCardArgs = {
  cardNr: Scalars["ID"]["input"];
  paginationParams: PaginationInput;
};

export type RootQueryTypeWccChargeHistoryForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  filterParams?: InputMaybe<WccChargeHistoryForCustomerFilterInput>;
  paginationParams: PaginationInput;
};

export type RootQueryTypeWccInvoiceArgs = {
  invoiceId: Scalars["ID"]["input"];
};

export type RootQueryTypeWccInvoicesForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  filterParams?: InputMaybe<WccInvoiceFilterInput>;
  paginationParams: PaginationInput;
};

export type RootQueryTypeWccOrdersForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  filterParams?: InputMaybe<WccOrderFilterInput>;
  paginationParams: PaginationInput;
};

export type RootQueryTypeWscCardArgs = {
  cardNr: Scalars["ID"]["input"];
};

export type RootQueryTypeWscCardsForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  filterParams?: InputMaybe<WscCardFilterInput>;
  paginationParams: PaginationInput;
};

export type RootQueryTypeWscCustomerArgs = {
  customerId: Scalars["ID"]["input"];
};

export type RootQueryTypeWscGoodsGroupsArgs = {
  customerId: Scalars["ID"]["input"];
};

export type RootQueryTypeWscInvoiceArgs = {
  invoiceId: Scalars["ID"]["input"];
};

export type RootQueryTypeWscInvoicesForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  filterParams?: InputMaybe<WscInvoiceFilterInput>;
  paginationParams: PaginationInput;
};

export type RootQueryTypeWscTransactionArgs = {
  transactionId: Scalars["ID"]["input"];
};

export type RootQueryTypeWscTransactionsForCustomerArgs = {
  customerId: Scalars["ID"]["input"];
  filterParams?: InputMaybe<WscTransactionFilterInput>;
  paginationParams: PaginationInput;
};

export enum Salutation {
  Company = "COMPANY",
  MarriedCouple = "MARRIED_COUPLE",
  Mr = "MR",
  Mrs = "MRS",
  MrsAndMr = "MRS_AND_MR",
  MrAndMrs = "MR_AND_MRS",
  NonBinary = "NON_BINARY",
  Unknown = "UNKNOWN",
}

export type SelectOption = {
  __typename?: "SelectOption";
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export enum ServiceRole {
  FleetAtHomeManager = "FLEET_AT_HOME_MANAGER",
  FleetAtHomeUser = "FLEET_AT_HOME_USER",
  GaasBasicAdmin = "GAAS_BASIC_ADMIN",
  GaasBasicBasic = "GAAS_BASIC_BASIC",
  GaasBasicFree = "GAAS_BASIC_FREE",
  GaasBasicPremium = "GAAS_BASIC_PREMIUM",
  ThgQuoteManager = "THG_QUOTE_MANAGER",
  VirtaUser = "VIRTA_USER",
  WccManager = "WCC_MANAGER",
  WestfalenIdAccountManager = "WESTFALEN_ID_ACCOUNT_MANAGER",
  WscManager = "WSC_MANAGER",
}

export enum ServiceSubscription {
  FleetAtHome = "FLEET_AT_HOME",
  GaasBasic = "GAAS_BASIC",
  ThgQuote = "THG_QUOTE",
  Virta = "VIRTA",
  Wcc = "WCC",
  WestfalenId = "WESTFALEN_ID",
  Wsc = "WSC",
}

export enum ThgQuoteDeletePendingVehicleRegistrationMutationError {
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type ThgQuoteDeletePendingVehicleRegistrationMutationResult = {
  __typename?: "ThgQuoteDeletePendingVehicleRegistrationMutationResult";
  errors: Array<ThgQuoteDeletePendingVehicleRegistrationMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type ThgQuotePendingVehicleRegistration = {
  __typename?: "ThgQuotePendingVehicleRegistration";
  externalId: Scalars["ID"]["output"];
  fin?: Maybe<Scalars["String"]["output"]>;
  vehicleAlias?: Maybe<Scalars["String"]["output"]>;
  vehicleCertificateFrontImageId: Scalars["ID"]["output"];
  vehicleClass: Scalars["String"]["output"];
};

export type ThgQuotePendingVehicleRegistrationInput = {
  fin?: InputMaybe<Scalars["String"]["input"]>;
  vehicleAlias?: InputMaybe<Scalars["String"]["input"]>;
  vehicleCertificateFrontImageId: Scalars["ID"]["input"];
  vehicleClass: Scalars["String"]["input"];
};

export enum ThgQuotePendingVehicleRegistrationMutationError {
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type ThgQuotePendingVehicleRegistrationMutationResult = {
  __typename?: "ThgQuotePendingVehicleRegistrationMutationResult";
  errors: Array<ThgQuotePendingVehicleRegistrationMutationError>;
  pendingVehicleRegistrationId?: Maybe<Scalars["ID"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ThgQuoteResubmitVehicleRegistrationMutationResult = {
  __typename?: "ThgQuoteResubmitVehicleRegistrationMutationResult";
  errors: Array<MutationError>;
  results: Array<ThgQuoteResubmitVehicleRegistrationResult>;
  success: Scalars["Boolean"]["output"];
};

export type ThgQuoteResubmitVehicleRegistrationResult = {
  __typename?: "ThgQuoteResubmitVehicleRegistrationResult";
  error?: Maybe<MutationError>;
  id: Scalars["ID"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type ThgQuoteVehicleClassInfo = {
  __typename?: "ThgQuoteVehicleClassInfo";
  currency?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  price?: Maybe<Scalars["Decimal"]["output"]>;
  vehicleClass: Scalars["ID"]["output"];
};

export type ThgQuoteVehicleRegistration = {
  __typename?: "ThgQuoteVehicleRegistration";
  creationDate: Scalars["Date"]["output"];
  fin?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  state: ThgQuoteVehicleRegistrationState;
  validForResubmission: Scalars["Boolean"]["output"];
  vehicleAlias: Scalars["String"]["output"];
  vehicleClass: Scalars["String"]["output"];
  vehicleOwner?: Maybe<Scalars["String"]["output"]>;
  year: Scalars["String"]["output"];
};

export type ThgQuoteVehicleRegistrationInfo = {
  __typename?: "ThgQuoteVehicleRegistrationInfo";
  addressDetailsNecessary: Scalars["Boolean"]["output"];
  vatIdentificationNumberNecessary: Scalars["Boolean"]["output"];
};

export type ThgQuoteVehicleRegistrationInput = {
  address?: InputMaybe<AddressInput>;
  customerId: Scalars["ID"]["input"];
  fin?: InputMaybe<Scalars["String"]["input"]>;
  vatIdentificationNumber?: InputMaybe<Scalars["String"]["input"]>;
  vehicleAlias?: InputMaybe<Scalars["String"]["input"]>;
  vehicleCertificateFrontImageId: Scalars["ID"]["input"];
  vehicleClass: Scalars["String"]["input"];
  year: Scalars["String"]["input"];
};

export enum ThgQuoteVehicleRegistrationMutationError {
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type ThgQuoteVehicleRegistrationMutationResult = {
  __typename?: "ThgQuoteVehicleRegistrationMutationResult";
  errors: Array<ThgQuoteVehicleRegistrationMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum ThgQuoteVehicleRegistrationState {
  Approved = "APPROVED",
  InReview = "IN_REVIEW",
  Paid = "PAID",
  PaymentPending = "PAYMENT_PENDING",
  Rejected = "REJECTED",
  Submitted = "SUBMITTED",
}

export type User = {
  __typename?: "User";
  activationState?: Maybe<Scalars["String"]["output"]>;
  addressDetails?: Maybe<AddressDetails>;
  bankDetails?: Maybe<BankDetails>;
  capabilities: UserCapabilities;
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName: Scalars["String"]["output"];
  permissions: Permissions;
  salutation: Salutation;
};

export type UserCapabilities = {
  __typename?: "UserCapabilities";
  canStoreAddressDetails: Scalars["Boolean"]["output"];
  canStoreBankDetails: Scalars["Boolean"]["output"];
};

export type UserDataInput = {
  address?: InputMaybe<AddressInput>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  salutation: Salutation;
};

export enum VerifyEmailMutationError {
  InternalError = "INTERNAL_ERROR",
  TooManyRequests = "TOO_MANY_REQUESTS",
  Unauthorized = "UNAUTHORIZED",
}

export type VerifyEmailMutationResult = {
  __typename?: "VerifyEmailMutationResult";
  errors: Array<VerifyEmailMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type WccCard = {
  __typename?: "WccCard";
  archivedAt?: Maybe<Scalars["Date"]["output"]>;
  cardNr: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  lastChargedAt?: Maybe<Scalars["Date"]["output"]>;
  reloadCount?: Maybe<Scalars["Int"]["output"]>;
  state: WccCardState;
  subscriptionAmount: Scalars["Decimal"]["output"];
  subscriptionState: WccSubscriptionState;
  totalAmountPerPeriod: Scalars["Decimal"]["output"];
};

export type WccCardChargeInput = {
  cardNr: Scalars["ID"]["input"];
  chargeAmount: Scalars["Decimal"]["input"];
};

export type WccCardFilterInput = {
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<WccCardState>;
  subscriptionStates?: InputMaybe<Array<WccSubscriptionState>>;
};

export type WccCardLabelInput = {
  cardNr: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
};

export enum WccCardMutationError {
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type WccCardMutationResult = {
  __typename?: "WccCardMutationResult";
  cardResults: Array<WccCardUpdateResult>;
  errors: Array<WccCardMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type WccCardPage = {
  __typename?: "WccCardPage";
  cards: Array<WccCard>;
  paginationMetadata: PaginationMetadata;
};

export enum WccCardState {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
}

export type WccCardSubscriptionInput = {
  cardNr: Scalars["ID"]["input"];
  subscriptionAmount?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type WccCardUpdateResult = {
  __typename?: "WccCardUpdateResult";
  cardNr: Scalars["ID"]["output"];
  error?: Maybe<WccCardUpdateResultError>;
  success: Scalars["Boolean"]["output"];
};

export enum WccCardUpdateResultError {
  CardAlreadyChargedToday = "CARD_ALREADY_CHARGED_TODAY",
  InternalError = "INTERNAL_ERROR",
}

export type WccCharge = {
  __typename?: "WccCharge";
  amount: Scalars["Decimal"]["output"];
  cardNr: Scalars["ID"]["output"];
  chargedAt: Scalars["Date"]["output"];
  isCancelable: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  type: WccChargeType;
};

export type WccChargeHistoryForCustomerFilterInput = {
  amountFrom?: InputMaybe<Scalars["Decimal"]["input"]>;
  amountTo?: InputMaybe<Scalars["Decimal"]["input"]>;
  dateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  dateTo?: InputMaybe<Scalars["Date"]["input"]>;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  types?: InputMaybe<Array<WccChargeType>>;
};

export type WccChargePage = {
  __typename?: "WccChargePage";
  charges: Array<WccCharge>;
  paginationMetadata: PaginationMetadata;
};

export enum WccChargeType {
  Credit = "CREDIT",
  OneTime = "ONE_TIME",
  Subscription = "SUBSCRIPTION",
}

export type WccChargesForCustomerPage = {
  __typename?: "WccChargesForCustomerPage";
  charges: Array<WccCharge>;
  downloadLink: Scalars["String"]["output"];
  paginationMetadata: PaginationMetadata;
};

export type WccInvoice = {
  __typename?: "WccInvoice";
  documentAvailable: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  invoicedAt: Scalars["Date"]["output"];
  netValue: Scalars["Decimal"]["output"];
  /** The url will not be set in a list request. It will only be set in a single request. */
  url?: Maybe<Scalars["String"]["output"]>;
};

export type WccInvoiceFilterInput = {
  dateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  dateTo?: InputMaybe<Scalars["Date"]["input"]>;
};

export type WccInvoicePage = {
  __typename?: "WccInvoicePage";
  invoices: Array<WccInvoice>;
  paginationMetadata: PaginationMetadata;
};

export type WccOrder = {
  __typename?: "WccOrder";
  batch1?: Maybe<WccOrderBatch>;
  batch2?: Maybe<WccOrderBatch>;
  batch3?: Maybe<WccOrderBatch>;
  createdAt: Scalars["DateTime"]["output"];
  orderNumber: Scalars["ID"]["output"];
  state: WccOrderState;
};

export type WccOrderBatch = {
  __typename?: "WccOrderBatch";
  cardQuantity: Scalars["Int"]["output"];
  subscriptionAmount: Scalars["Decimal"]["output"];
};

export type WccOrderBatchInput = {
  cardQuantity: Scalars["Int"]["input"];
  subscriptionAmount: Scalars["Decimal"]["input"];
};

export type WccOrderDataInput = {
  batch1: WccOrderBatchInput;
  batch2?: InputMaybe<WccOrderBatchInput>;
  batch3?: InputMaybe<WccOrderBatchInput>;
  customerId: Scalars["ID"]["input"];
};

export type WccOrderFilterInput = {
  dateFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  states?: InputMaybe<Array<WccOrderState>>;
};

export type WccOrderPage = {
  __typename?: "WccOrderPage";
  orders: Array<WccOrder>;
  paginationMetadata: PaginationMetadata;
};

export enum WccOrderState {
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  New = "NEW",
  Received = "RECEIVED",
  Unknown = "UNKNOWN",
}

export enum WccPlaceCardOrderMutationError {
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type WccPlaceCardOrderMutationResult = {
  __typename?: "WccPlaceCardOrderMutationResult";
  errors: Array<WccPlaceCardOrderMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum WccSubscriptionState {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type WscAddressInput = {
  addition?: InputMaybe<Scalars["String"]["input"]>;
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  houseNumber: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  street: Scalars["String"]["input"];
};

export type WscCard = {
  __typename?: "WscCard";
  acceptanceType: WscCardAcceptanceType;
  additionalText?: Maybe<Scalars["String"]["output"]>;
  alternativeShippingAddress?: Maybe<WscCardAddress>;
  bookingText?: Maybe<Scalars["String"]["output"]>;
  cardNr: Scalars["ID"]["output"];
  costCenter?: Maybe<Scalars["String"]["output"]>;
  /** The customer to which this wscCard belongs to. Attention: only request this field if you really need it. It causes additional lookups. */
  customer?: Maybe<WscCustomer>;
  /** The card pin is encrypted and has to be decrypted. It should only be requested for single cards. */
  decryptedCardPin?: Maybe<Scalars["String"]["output"]>;
  electricityCardEnabled: Scalars["Boolean"]["output"];
  electricityCardInformation?: Maybe<WscCardElectricityCardInformation>;
  expiresAt: Scalars["String"]["output"];
  futureBlockDate?: Maybe<Scalars["Date"]["output"]>;
  gasStation?: Maybe<Scalars["String"]["output"]>;
  gasStationId?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  maxTransactionsPerDay: Scalars["Int"]["output"];
  permissionLevel: WscCardPermissionLevel;
  requestInfoEntry: Scalars["Boolean"]["output"];
  requestKilometresEntry: Scalars["Boolean"]["output"];
  state: WscCardState;
  virtualCardEnabled: Scalars["Boolean"]["output"];
  virtualCardInformation?: Maybe<WscCardVirtualCardInformation>;
};

export enum WscCardAcceptanceType {
  AssociationCard = "ASSOCIATION_CARD",
  NetworkCard = "NETWORK_CARD",
  StationCard = "STATION_CARD",
}

export type WscCardAddress = {
  __typename?: "WscCardAddress";
  addition?: Maybe<Scalars["String"]["output"]>;
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  postalCode: Scalars["String"]["output"];
  street: Scalars["String"]["output"];
};

export type WscCardElectricityCardInformation = {
  __typename?: "WscCardElectricityCardInformation";
  /** The email address recorded directly on the electricity card */
  email?: Maybe<Scalars["String"]["output"]>;
  userAssigned: Scalars["Boolean"]["output"];
  /** The user to which this wscCard belongs to. Attention: only request this field if you really need it. It causes additional lookups. */
  userInformation?: Maybe<WscCardElectricityCardUserInformation>;
};

export type WscCardElectricityCardUserInformation = {
  __typename?: "WscCardElectricityCardUserInformation";
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export type WscCardFilterInput = {
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  states?: InputMaybe<Array<WscCardState>>;
};

export enum WscCardMutationError {
  EmailAlreadyAssigned = "EMAIL_ALREADY_ASSIGNED",
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type WscCardMutationResult = {
  __typename?: "WscCardMutationResult";
  errors: Array<WscCardMutationError>;
  success: Scalars["Boolean"]["output"];
};

export enum WscCardOrderPermissionLevel {
  DriverService = "DRIVER_SERVICE",
  FuelService = "FUEL_SERVICE",
  VehicleService = "VEHICLE_SERVICE",
}

export type WscCardPage = {
  __typename?: "WscCardPage";
  cards: Array<WscCard>;
  downloadLink: Scalars["String"]["output"];
  paginationMetadata: PaginationMetadata;
};

export enum WscCardPermissionLevel {
  DriverService = "DRIVER_SERVICE",
  FuelService = "FUEL_SERVICE",
  NaturalGas = "NATURAL_GAS",
  SingleFuel = "SINGLE_FUEL",
  VehicleService = "VEHICLE_SERVICE",
}

export enum WscCardState {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Ordered = "ORDERED",
}

export type WscCardUpdateInput = {
  acceptanceType: WscCardAcceptanceType;
  additionalText?: InputMaybe<Scalars["String"]["input"]>;
  bookingText?: InputMaybe<Scalars["String"]["input"]>;
  costCenter?: InputMaybe<Scalars["String"]["input"]>;
  electricityCardEmail?: InputMaybe<Scalars["String"]["input"]>;
  electricityCardEnabled: Scalars["Boolean"]["input"];
  gasStationId?: InputMaybe<Scalars["ID"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  maxTransactionsPerDay?: InputMaybe<Scalars["Int"]["input"]>;
  permissionLevel?: InputMaybe<WscCardOrderPermissionLevel>;
  pin?: InputMaybe<Scalars["String"]["input"]>;
  requestInfoEntry: Scalars["Boolean"]["input"];
  requestKilometresEntry: Scalars["Boolean"]["input"];
  virtualCardInformation?: InputMaybe<WscVirtualCardInput>;
};

export enum WscCardVirtualAppIdentification {
  Easymove = "EASYMOVE",
  Fillibri = "FILLIBRI",
}

export type WscCardVirtualCardInformation = {
  __typename?: "WscCardVirtualCardInformation";
  appIdentification: WscCardVirtualAppIdentification;
  contactType: WscCardVirtualContactType;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  salutation?: Maybe<Salutation>;
};

export enum WscCardVirtualContactType {
  Email = "EMAIL",
  PhoneNumber = "PHONE_NUMBER",
}

export type WscCustomer = {
  __typename?: "WscCustomer";
  id: Scalars["ID"]["output"];
  openSalesVolumeInCurrentBillingPeriod: Scalars["Decimal"]["output"];
  salesLimitPerBillingPeriod: Scalars["Decimal"]["output"];
  shippingAddress: WscCardAddress;
  usedSalesVolumeInCurrentBillingPeriod: Scalars["Decimal"]["output"];
};

export type WscInvoice = {
  __typename?: "WscInvoice";
  documentAvailable: Scalars["Boolean"]["output"];
  grossValue: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  invoicedAt: Scalars["Date"]["output"];
  /** The url will not be set in a list request. It will only be set in a single request. */
  url?: Maybe<Scalars["String"]["output"]>;
};

export type WscInvoiceFilterInput = {
  dateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  dateTo?: InputMaybe<Scalars["Date"]["input"]>;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
};

export type WscInvoicePage = {
  __typename?: "WscInvoicePage";
  invoices: Array<WscInvoice>;
  paginationMetadata: PaginationMetadata;
};

export enum WscMutationError {
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type WscMutationResult = {
  __typename?: "WscMutationResult";
  errors: Array<WscMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type WscOrderDataInput = {
  acceptanceType: WscCardAcceptanceType;
  additionalText?: InputMaybe<Scalars["String"]["input"]>;
  alternativeShippingAddress?: InputMaybe<WscAddressInput>;
  bookingText?: InputMaybe<Scalars["String"]["input"]>;
  costCenter?: InputMaybe<Scalars["String"]["input"]>;
  customerId: Scalars["ID"]["input"];
  electricityCardEmail?: InputMaybe<Scalars["String"]["input"]>;
  electricityCardEnabled: Scalars["Boolean"]["input"];
  gasStationId?: InputMaybe<Scalars["ID"]["input"]>;
  label: Scalars["String"]["input"];
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  maxTransactionsPerDay: Scalars["Int"]["input"];
  permissionLevel: WscCardOrderPermissionLevel;
  pin: Scalars["String"]["input"];
  requestInfoEntry: Scalars["Boolean"]["input"];
  requestKilometresEntry: Scalars["Boolean"]["input"];
  virtualCardInformation?: InputMaybe<WscVirtualCardInput>;
};

export type WscReplacementCardOrderDataInput = {
  alternativeShippingAddress?: InputMaybe<WscAddressInput>;
  replacementType: WscReplacementType;
};

export enum WscReplacementType {
  NewCardNr = "NEW_CARD_NR",
  SameCardNr = "SAME_CARD_NR",
}

export enum WscSendVirtualCardActivationLinkMutationError {
  AlreadySent = "ALREADY_SENT",
  InternalError = "INTERNAL_ERROR",
  InvalidParams = "INVALID_PARAMS",
  Unauthorized = "UNAUTHORIZED",
}

export type WscSendVirtualCardActivationLinkMutationResult = {
  __typename?: "WscSendVirtualCardActivationLinkMutationResult";
  errors: Array<WscSendVirtualCardActivationLinkMutationError>;
  success: Scalars["Boolean"]["output"];
};

export type WscStation = {
  __typename?: "WscStation";
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type WscTransaction = {
  __typename?: "WscTransaction";
  card?: Maybe<WscCard>;
  city: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  gasStation: Scalars["String"]["output"];
  goodsGroup: Scalars["String"]["output"];
  grossValue: Scalars["Decimal"]["output"];
  id: Scalars["String"]["output"];
  info?: Maybe<Scalars["String"]["output"]>;
  mileage?: Maybe<Scalars["Int"]["output"]>;
  postalCode: Scalars["String"]["output"];
  quantity: Scalars["Decimal"]["output"];
  receiptNr: Scalars["String"]["output"];
  street: Scalars["String"]["output"];
  transactionCardData: WscTransactionCardData;
  unit: Scalars["String"]["output"];
};

export type WscTransactionCardData = {
  __typename?: "WscTransactionCardData";
  additionalText?: Maybe<Scalars["String"]["output"]>;
  bookingText?: Maybe<Scalars["String"]["output"]>;
  cardNr: Scalars["ID"]["output"];
  costCenter?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  licensePlate?: Maybe<Scalars["String"]["output"]>;
};

export type WscTransactionFilterInput = {
  dateFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  goodsGroups?: InputMaybe<Array<Scalars["String"]["input"]>>;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
};

export type WscTransactionPage = {
  __typename?: "WscTransactionPage";
  downloadLink: Scalars["String"]["output"];
  paginationMetadata: PaginationMetadata;
  transactions: Array<WscTransaction>;
};

export type WscVirtualCardInput = {
  contactType: WscCardVirtualContactType;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  salutation: Salutation;
};

export type UpdateBankDetailsMutationVariables = Exact<{
  accountOwner: Scalars["String"]["input"];
  iban: Scalars["String"]["input"];
  bic?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateBankDetailsMutation = {
  __typename?: "RootMutationType";
  updateBankDetails: {
    __typename?: "MutationResult";
    success: boolean;
    errors: Array<MutationError>;
  };
};

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type UpdateEmailMutation = {
  __typename?: "RootMutationType";
  updateEmail: {
    __typename?: "MutationResult";
    success: boolean;
    errors: Array<MutationError>;
  };
};

export type UpdatePasswordMutationVariables = Exact<{
  password: Scalars["String"]["input"];
}>;

export type UpdatePasswordMutation = {
  __typename?: "RootMutationType";
  updatePassword: {
    __typename?: "MutationResult";
    success: boolean;
    errors: Array<MutationError>;
  };
};

export type UpdateUserDataMutationVariables = Exact<{
  userData: UserDataInput;
}>;

export type UpdateUserDataMutation = {
  __typename?: "RootMutationType";
  updateUserData: {
    __typename?: "MutationResult";
    success: boolean;
    errors: Array<MutationError>;
  };
};

export type DeleteMyUserMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteMyUserMutation = {
  __typename?: "RootMutationType";
  deleteMyUser: {
    __typename?: "MutationResult";
    success: boolean;
    errors: Array<MutationError>;
  };
};

export type FinishRegistrationMutationVariables = Exact<{
  address: AddressInput;
}>;

export type FinishRegistrationMutation = {
  __typename?: "RootMutationType";
  finishRegistration: {
    __typename?: "MutationResult";
    errors: Array<MutationError>;
    success: boolean;
  };
};

export type GetFleetAtHomeTransactionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetFleetAtHomeTransactionsQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeTransactions: Array<{
    __typename?: "FleetAtHomeTransaction";
    amount?: string | null;
    creditState: FleetAtHomeTransactionCreditState;
    energyAmountKilowatthours?: string | null;
    id: string;
    startTime: any;
    stopTime?: any | null;
  }>;
};

export type GetFleetAtHomeCreditNotesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetFleetAtHomeCreditNotesQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeCreditNotes: Array<{
    __typename?: "FleetAtHomeCreditNote";
    dateFrom: any;
    dateTo: any;
    energyAmountKilowatthours: string;
    forecasted: boolean;
    grossAmountInEuro?: string | null;
    id?: string | null;
    numberOfTransactions: number;
    url?: string | null;
  }>;
};

export type GetFleetAtHomeForecastedCreditNotesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetFleetAtHomeForecastedCreditNotesQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeForecastedCreditNotes: Array<{
    __typename?: "FleetAtHomeCreditNote";
    dateFrom: any;
    dateTo: any;
    energyAmountKilowatthours: string;
    forecasted: boolean;
    grossAmountInEuro?: string | null;
    id?: string | null;
    numberOfTransactions: number;
    url?: string | null;
  }>;
};

export type FleetAtHomeShopProductCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FleetAtHomeShopProductCategoriesQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeShopProductCategories: Array<string>;
};

export type FleetAtHomeShopProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FleetAtHomeShopProductsQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeShopProducts: Array<{
    __typename?: "FleetAtHomeShopProduct";
    id: string;
    name: string;
    shortDescription: string;
    detailedDescription: string;
    price: string;
    listPrice: string;
    currency: string;
    category: string;
    image?: {
      __typename?: "FleetAtHomeShopProductImage";
      mimeType: string;
      content: string;
    } | null;
  }>;
};

export type FleetAtHomeOrderPreviewQueryVariables = Exact<{
  data: FleetAtHomeOrderPreviewDataInput;
}>;

export type FleetAtHomeOrderPreviewQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeOrderPreview: {
    __typename?: "FleetAtHomeOrderPreview";
    totalAmount: string;
    netAmount: string;
    shipping: string;
    vatAmount: string;
    items: Array<{
      __typename?: "FleetAtHomeOrderPreviewItem";
      id: string;
      name: string;
      price: string;
      quantity: string;
    }>;
  };
};

export type FleetAtHomePlaceOrderMutationVariables = Exact<{
  data: FleetAtHomeOrderInput;
}>;

export type FleetAtHomePlaceOrderMutation = {
  __typename?: "RootMutationType";
  fleetAtHomePlaceOrder: {
    __typename?: "FleetAtHomePlaceOrderMutationResult";
    success: boolean;
    errors: Array<FleetAtHomePlaceOrderMutationError>;
  };
};

export type FleetAtHomeShopProductQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FleetAtHomeShopProductQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeShopProduct: {
    __typename?: "FleetAtHomeShopProduct";
    id: string;
    name: string;
    shortDescription: string;
    detailedDescription: string;
    price: string;
    listPrice: string;
    currency: string;
    category: string;
    image?: {
      __typename?: "FleetAtHomeShopProductImage";
      mimeType: string;
      content: string;
    } | null;
    configurationOptions: Array<{
      __typename?: "FleetAtHomeShopProductAccessory";
      id: string;
      shortDescription: string;
      price: string;
      listPrice: string;
      currency: string;
    }>;
    obligatoryConfigurationOptions: Array<{
      __typename?: "FleetAtHomeShopProductAccessory";
      id: string;
      shortDescription: string;
      price: string;
      listPrice: string;
      currency: string;
    }>;
    accessories: Array<{
      __typename?: "FleetAtHomeShopProductAccessory";
      id: string;
      shortDescription: string;
      price: string;
      listPrice: string;
      currency: string;
    }>;
  };
};

export type FleetAtHomeProposeElectricityPriceMutationVariables = Exact<{
  dateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  electricityPricePerKilowattHour: Scalars["Decimal"]["input"];
  stationId: Scalars["ID"]["input"];
  electricityPriceCertificateId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type FleetAtHomeProposeElectricityPriceMutation = {
  __typename?: "RootMutationType";
  fleetAtHomeProposeElectricityPrice: {
    __typename?: "FleetAtHomeProposeElectricityPriceMutationResult";
    errors: Array<FleetAtHomeProposeElectricityPriceMutationError>;
    success: boolean;
  };
};

export type FleetAtHomeUpdateStationMutationVariables = Exact<{
  stationId: Scalars["ID"]["input"];
  alias: Scalars["String"]["input"];
  street?: InputMaybe<Scalars["String"]["input"]>;
  houseNumber?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FleetAtHomeUpdateStationMutation = {
  __typename?: "RootMutationType";
  fleetAtHomeUpdateStation: {
    __typename?: "FleetAtHomeUpdateStationMutationResult";
    errors: Array<FleetAtHomeUpdateStationMutationError>;
    success: boolean;
  };
};

export type FleetAtHomeWithdrawElectricityPriceMutationVariables = Exact<{
  stationId: Scalars["ID"]["input"];
}>;

export type FleetAtHomeWithdrawElectricityPriceMutation = {
  __typename?: "RootMutationType";
  fleetAtHomeWithdrawProposedElectricityPrice: {
    __typename?: "MutationResult";
    errors: Array<MutationError>;
    success: boolean;
  };
};

export type GetFleetAtHomeElectricityPriceHistoryForStationQueryVariables =
  Exact<{
    stationId: Scalars["ID"]["input"];
  }>;

export type GetFleetAtHomeElectricityPriceHistoryForStationQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeElectricityPriceHistoryForStation: Array<{
    __typename?: "FleetAtHomeElectricityPrice";
    id: string;
    createdAt: any;
    dateFrom: any;
    dateTo?: any | null;
    electricityPricePerKilowattHour: string;
    state: FleetAtHomeElectricityPriceState;
    reason?: string | null;
    url?: string | null;
  }>;
};

export type GetFleetAtHomeStationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetFleetAtHomeStationsQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeStations: Array<{
    __typename?: "FleetAtHomeStation";
    id: string;
    setupNecessary: boolean;
    name: string;
    alias?: string | null;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    country: string;
    state: FleetAtHomeStationState;
    earliestPossibleElectricityPriceProposal: any;
    currentElectricityPrice?: {
      __typename?: "FleetAtHomeElectricityPrice";
      createdAt: any;
      electricityPricePerKilowattHour: string;
      state: FleetAtHomeElectricityPriceState;
      dateFrom: any;
      url?: string | null;
    } | null;
    proposedElectricityPrice?: {
      __typename?: "FleetAtHomeElectricityPrice";
      createdAt: any;
      electricityPricePerKilowattHour: string;
      state: FleetAtHomeElectricityPriceState;
      dateFrom: any;
      reason?: string | null;
      url?: string | null;
    } | null;
    order?: {
      __typename?: "FleetAtHomeOrder";
      id: string;
      invoiceUrl: string;
      netAmount: string;
      state: string;
      totalAmount: string;
      trackingNumber?: string | null;
      vatAmount: string;
    } | null;
  }>;
};

export type FleetAtHomeAcceptProposedElectricityPriceMutationVariables = Exact<{
  stationId: Scalars["ID"]["input"];
}>;

export type FleetAtHomeAcceptProposedElectricityPriceMutation = {
  __typename?: "RootMutationType";
  fleetAtHomeAcceptProposedElectricityPrice: {
    __typename?: "FleetAtHomeAcceptProposedElectricityPriceMutationResult";
    errors: Array<FleetAtHomeAcceptProposedElectricityPriceMutationError>;
    success: boolean;
  };
};

export type FleetAtHomeAssignWscToUserMutationVariables = Exact<{
  cardNr: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
}>;

export type FleetAtHomeAssignWscToUserMutation = {
  __typename?: "RootMutationType";
  fleetAtHomeAssignWscToUser: {
    __typename?: "FleetAtHomeAssignWscToUserMutationResult";
    errors: Array<FleetAtHomeAssignWscToUserMutationError>;
    success: boolean;
  };
};

export type FleetAtHomeDeclineProposedElectricityPriceMutationVariables =
  Exact<{
    stationId: Scalars["ID"]["input"];
    reason?: InputMaybe<Scalars["String"]["input"]>;
  }>;

export type FleetAtHomeDeclineProposedElectricityPriceMutation = {
  __typename?: "RootMutationType";
  fleetAtHomeDeclineProposedElectricityPrice: {
    __typename?: "FleetAtHomeDeclineProposedElectricityPriceMutationResult";
    errors: Array<FleetAtHomeDeclineProposedElectricityPriceMutationError>;
    success: boolean;
  };
};

export type FleetAtHomeReleaseWscFromUserMutationVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
}>;

export type FleetAtHomeReleaseWscFromUserMutation = {
  __typename?: "RootMutationType";
  fleetAtHomeReleaseWscFromUser: {
    __typename?: "FleetAtHomeReleaseWscFromUserMutationResult";
    errors: Array<FleetAtHomeReleaseWscFromUserMutationError>;
    success: boolean;
  };
};

export type GetFleetAtHomeFreeWscsForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
}>;

export type GetFleetAtHomeFreeWscsForCustomerQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeFreeWscsForCustomer: Array<{
    __typename?: "FleetAtHomeWsc";
    cardNr?: string | null;
    label?: string | null;
  }>;
};

export type GetFleetAtHomeUserDataForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
}>;

export type GetFleetAtHomeUserDataForCustomerQuery = {
  __typename?: "RootQueryType";
  fleetAtHomeUserDataForCustomer: Array<{
    __typename?: "FleetAtHomeUserData";
    id: string;
    email: string;
    firstName?: string | null;
    lastName: string;
    registrationLink?: string | null;
    wscNumber?: string | null;
    state: FleetAtHomeUserState;
    createdAt: any;
    stations: Array<{
      __typename?: "FleetAtHomeStation";
      id: string;
      name: string;
      city: string;
      postalCode: string;
      street: string;
      houseNumber: string;
      currentElectricityPrice?: {
        __typename?: "FleetAtHomeElectricityPrice";
        electricityPricePerKilowattHour: string;
        state: FleetAtHomeElectricityPriceState;
        dateTo?: any | null;
        url?: string | null;
      } | null;
      proposedElectricityPrice?: {
        __typename?: "FleetAtHomeElectricityPrice";
        electricityPricePerKilowattHour: string;
        state: FleetAtHomeElectricityPriceState;
        dateFrom: any;
        createdAt: any;
        url?: string | null;
      } | null;
    }>;
  }>;
};

export type FleetAtHomeInviteUserMutationVariables = Exact<{
  invitationData: InvitationDataInput;
}>;

export type FleetAtHomeInviteUserMutation = {
  __typename?: "RootMutationType";
  fleetAtHomeInviteUser: {
    __typename?: "InviteUserMutationResult";
    errors: Array<InviteUserMutationError>;
    success: boolean;
    caseNumber?: string | null;
  };
};

export type GetActiveMaintenanceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActiveMaintenanceQuery = {
  __typename?: "RootQueryType";
  activeMaintenance?: {
    __typename?: "Maintenance";
    dateFrom: any;
    dateTo?: any | null;
  } | null;
};

export type RegisterExistingCustomerMutationVariables = Exact<{
  registrationData: RegisterExistingCustomerInput;
}>;

export type RegisterExistingCustomerMutation = {
  __typename?: "RootMutationType";
  registerExistingCustomer: {
    __typename?: "RegisterExistingCustomerMutationResult";
    caseNumber?: string | null;
    errors: Array<RegisterExistingCustomerMutationError>;
    success: boolean;
  };
};

export type GetRegistrationDataQueryVariables = Exact<{
  provisioningKey: Scalars["String"]["input"];
}>;

export type GetRegistrationDataQuery = {
  __typename?: "RootQueryType";
  registrationData?: {
    __typename?: "RegistrationDataResult";
    errors: Array<RegistrationDataError>;
    success: boolean;
    registrationData?: {
      __typename?: "RegistrationData";
      salutation?: Salutation | null;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    } | null;
  } | null;
};

export type RegisterFleetAtHomeUserMutationVariables = Exact<{
  registrationData: FleetAtHomeRegistrationDataInput;
}>;

export type RegisterFleetAtHomeUserMutation = {
  __typename?: "RootMutationType";
  registerFleetAtHomeUser: {
    __typename?: "RegisterFleetAtHomeUserMutationResult";
    success: boolean;
    errors: Array<RegisterFleetAtHomeUserMutationError>;
  };
};

export type RegisterUserMutationVariables = Exact<{
  registrationData: RegistrationDataInput;
}>;

export type RegisterUserMutation = {
  __typename?: "RootMutationType";
  registerUser: {
    __typename?: "RegisterUserMutationResult";
    success: boolean;
    errors: Array<RegisterUserMutationError>;
  };
};

export type VerifyEmailMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
}>;

export type VerifyEmailMutation = {
  __typename?: "RootMutationType";
  verifyEmail: {
    __typename?: "VerifyEmailMutationResult";
    success: boolean;
    errors: Array<VerifyEmailMutationError>;
  };
};

export type ThgQuotePreregisterVehicleMutationVariables = Exact<{
  data: ThgQuotePendingVehicleRegistrationInput;
}>;

export type ThgQuotePreregisterVehicleMutation = {
  __typename?: "RootMutationType";
  thgQuotePreregisterVehicle: {
    __typename?: "ThgQuotePendingVehicleRegistrationMutationResult";
    errors: Array<ThgQuotePendingVehicleRegistrationMutationError>;
    success: boolean;
    pendingVehicleRegistrationId?: string | null;
  };
};

export type GetThgQuoteVehicleRegistrationsForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
}>;

export type GetThgQuoteVehicleRegistrationsForCustomerQuery = {
  __typename?: "RootQueryType";
  thgQuoteVehicleRegistrationsForCustomer: Array<{
    __typename?: "ThgQuoteVehicleRegistration";
    id: string;
    state: ThgQuoteVehicleRegistrationState;
    vehicleAlias: string;
    vehicleClass: string;
    vehicleOwner?: string | null;
    year: string;
    fin?: string | null;
    creationDate: any;
    validForResubmission: boolean;
  }>;
};

export type ThgQuoteResubmitVehicleRegistrationsMutationVariables = Exact<{
  applicationIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
  customerId: Scalars["ID"]["input"];
}>;

export type ThgQuoteResubmitVehicleRegistrationsMutation = {
  __typename?: "RootMutationType";
  thgQuoteResubmitVehicleRegistrations: {
    __typename?: "ThgQuoteResubmitVehicleRegistrationMutationResult";
    success: boolean;
    errors: Array<MutationError>;
    results: Array<{
      __typename?: "ThgQuoteResubmitVehicleRegistrationResult";
      id: string;
      error?: MutationError | null;
      success: boolean;
    }>;
  };
};

export type ThgQuoteDeletePendingVehicleRegistrationMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ThgQuoteDeletePendingVehicleRegistrationMutation = {
  __typename?: "RootMutationType";
  thgQuoteDeletePendingVehicleRegistration: {
    __typename?: "ThgQuoteDeletePendingVehicleRegistrationMutationResult";
    success: boolean;
    errors: Array<ThgQuoteDeletePendingVehicleRegistrationMutationError>;
  };
};

export type ThgQuoteRegisterVehicleMutationVariables = Exact<{
  data: ThgQuoteVehicleRegistrationInput;
}>;

export type ThgQuoteRegisterVehicleMutation = {
  __typename?: "RootMutationType";
  thgQuoteRegisterVehicle: {
    __typename?: "ThgQuoteVehicleRegistrationMutationResult";
    success: boolean;
    errors: Array<ThgQuoteVehicleRegistrationMutationError>;
  };
};

export type ThgQuoteVehicleRegistrationInfoQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
}>;

export type ThgQuoteVehicleRegistrationInfoQuery = {
  __typename?: "RootQueryType";
  thgQuoteVehicleRegistrationInfo: {
    __typename?: "ThgQuoteVehicleRegistrationInfo";
    addressDetailsNecessary: boolean;
    vatIdentificationNumberNecessary: boolean;
  };
};

export type WccReactivateCardsMutationVariables = Exact<{
  cardNumbers: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  customerId: Scalars["ID"]["input"];
}>;

export type WccReactivateCardsMutation = {
  __typename?: "RootMutationType";
  wccReactivateCards: {
    __typename?: "WccCardMutationResult";
    success: boolean;
    errors: Array<WccCardMutationError>;
    cardResults: Array<{
      __typename?: "WccCardUpdateResult";
      cardNr: string;
      error?: WccCardUpdateResultError | null;
      success: boolean;
    }>;
  };
};

export type ActivateCardSubscriptionsMutationVariables = Exact<{
  cardSubscriptions: Array<WccCardSubscriptionInput> | WccCardSubscriptionInput;
  customerId: Scalars["ID"]["input"];
}>;

export type ActivateCardSubscriptionsMutation = {
  __typename?: "RootMutationType";
  wccActivateCardSubscriptions: {
    __typename?: "WccCardMutationResult";
    errors: Array<WccCardMutationError>;
    success: boolean;
    cardResults: Array<{
      __typename?: "WccCardUpdateResult";
      cardNr: string;
      error?: WccCardUpdateResultError | null;
      success: boolean;
    }>;
  };
};

export type WccChargeCardsMutationVariables = Exact<{
  cardCharges: Array<WccCardChargeInput> | WccCardChargeInput;
  customerId: Scalars["ID"]["input"];
}>;

export type WccChargeCardsMutation = {
  __typename?: "RootMutationType";
  wccChargeCards: {
    __typename?: "WccCardMutationResult";
    errors: Array<WccCardMutationError>;
    success: boolean;
    cardResults: Array<{
      __typename?: "WccCardUpdateResult";
      cardNr: string;
      error?: WccCardUpdateResultError | null;
      success: boolean;
    }>;
  };
};

export type GetWccChargeHistoryForCardQueryVariables = Exact<{
  cardNr: Scalars["ID"]["input"];
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
}>;

export type GetWccChargeHistoryForCardQuery = {
  __typename?: "RootQueryType";
  wccChargeHistoryForCard: {
    __typename?: "WccChargePage";
    charges: Array<{
      __typename?: "WccCharge";
      amount: string;
      chargedAt: any;
      type: WccChargeType;
      cardNr: string;
      label: string;
      isCancelable: boolean;
    }>;
    paginationMetadata: {
      __typename?: "PaginationMetadata";
      page: number;
      pageSize: number;
      totalEntries: number;
      totalPages: number;
    };
  };
};

export type DeactivateCardSubscriptionsMutationVariables = Exact<{
  cardNumbers: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  customerId: Scalars["ID"]["input"];
}>;

export type DeactivateCardSubscriptionsMutation = {
  __typename?: "RootMutationType";
  wccDeactivateCardSubscriptions: {
    __typename?: "WccCardMutationResult";
    errors: Array<WccCardMutationError>;
    success: boolean;
    cardResults: Array<{
      __typename?: "WccCardUpdateResult";
      cardNr: string;
      error?: WccCardUpdateResultError | null;
      success: boolean;
    }>;
  };
};

export type WccDeactivateCardsMutationVariables = Exact<{
  cardNumbers: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  customerId: Scalars["ID"]["input"];
}>;

export type WccDeactivateCardsMutation = {
  __typename?: "RootMutationType";
  wccDeactivateCards: {
    __typename?: "WccCardMutationResult";
    success: boolean;
    errors: Array<WccCardMutationError>;
    cardResults: Array<{
      __typename?: "WccCardUpdateResult";
      cardNr: string;
      error?: WccCardUpdateResultError | null;
      success: boolean;
    }>;
  };
};

export type WccPlaceCardOrderMutationVariables = Exact<{
  orderData: WccOrderDataInput;
}>;

export type WccPlaceCardOrderMutation = {
  __typename?: "RootMutationType";
  wccPlaceCardOrder: {
    __typename?: "WccPlaceCardOrderMutationResult";
    success: boolean;
    errors: Array<WccPlaceCardOrderMutationError>;
  };
};

export type WccUpdateCardLabelsMutationVariables = Exact<{
  cardLabels: Array<WccCardLabelInput> | WccCardLabelInput;
  customerId: Scalars["ID"]["input"];
}>;

export type WccUpdateCardLabelsMutation = {
  __typename?: "RootMutationType";
  wccUpdateCardLabels: {
    __typename?: "WccCardMutationResult";
    success: boolean;
    errors: Array<WccCardMutationError>;
    cardResults: Array<{
      __typename?: "WccCardUpdateResult";
      cardNr: string;
      error?: WccCardUpdateResultError | null;
      success: boolean;
    }>;
  };
};

export type WccUpdateCardSubscriptionAmountsMutationVariables = Exact<{
  cardSubscriptions: Array<WccCardSubscriptionInput> | WccCardSubscriptionInput;
  customerId: Scalars["ID"]["input"];
}>;

export type WccUpdateCardSubscriptionAmountsMutation = {
  __typename?: "RootMutationType";
  wccUpdateCardSubscriptionAmounts: {
    __typename?: "WccCardMutationResult";
    success: boolean;
    errors: Array<WccCardMutationError>;
    cardResults: Array<{
      __typename?: "WccCardUpdateResult";
      cardNr: string;
      error?: WccCardUpdateResultError | null;
      success: boolean;
    }>;
  };
};

export type GetWccChargeHistoryForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  dateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  dateTo?: InputMaybe<Scalars["Date"]["input"]>;
  types?: InputMaybe<Array<WccChargeType> | WccChargeType>;
  amountFrom?: InputMaybe<Scalars["Decimal"]["input"]>;
  amountTo?: InputMaybe<Scalars["Decimal"]["input"]>;
}>;

export type GetWccChargeHistoryForCustomerQuery = {
  __typename?: "RootQueryType";
  wccChargeHistoryForCustomer: {
    __typename?: "WccChargesForCustomerPage";
    downloadLink: string;
    charges: Array<{
      __typename?: "WccCharge";
      amount: string;
      cardNr: string;
      chargedAt: any;
      label: string;
      type: WccChargeType;
      isCancelable: boolean;
    }>;
    paginationMetadata: {
      __typename?: "PaginationMetadata";
      page: number;
      pageSize: number;
      totalEntries: number;
      totalPages: number;
    };
  };
};

export type GetWccInvoiceQueryVariables = Exact<{
  invoiceId: Scalars["ID"]["input"];
}>;

export type GetWccInvoiceQuery = {
  __typename?: "RootQueryType";
  wccInvoice: {
    __typename?: "WccInvoice";
    id: string;
    invoicedAt: any;
    netValue: string;
    documentAvailable: boolean;
    url?: string | null;
  };
};

export type GetWccInvoicesForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  dateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  dateTo?: InputMaybe<Scalars["Date"]["input"]>;
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
}>;

export type GetWccInvoicesForCustomerQuery = {
  __typename?: "RootQueryType";
  wccInvoicesForCustomer: {
    __typename?: "WccInvoicePage";
    invoices: Array<{
      __typename?: "WccInvoice";
      id: string;
      invoicedAt: any;
      netValue: string;
      documentAvailable: boolean;
      url?: string | null;
    }>;
    paginationMetadata: {
      __typename?: "PaginationMetadata";
      page: number;
      pageSize: number;
      totalEntries: number;
      totalPages: number;
    };
  };
};

export type GetWccOrdersForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  dateFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  states?: InputMaybe<Array<WccOrderState> | WccOrderState>;
}>;

export type GetWccOrdersForCustomerQuery = {
  __typename?: "RootQueryType";
  wccOrdersForCustomer: {
    __typename?: "WccOrderPage";
    orders: Array<{
      __typename?: "WccOrder";
      createdAt: any;
      orderNumber: string;
      state: WccOrderState;
      batch1?: {
        __typename?: "WccOrderBatch";
        cardQuantity: number;
        subscriptionAmount: string;
      } | null;
      batch2?: {
        __typename?: "WccOrderBatch";
        cardQuantity: number;
        subscriptionAmount: string;
      } | null;
      batch3?: {
        __typename?: "WccOrderBatch";
        cardQuantity: number;
        subscriptionAmount: string;
      } | null;
    }>;
    paginationMetadata: {
      __typename?: "PaginationMetadata";
      page: number;
      pageSize: number;
      totalEntries: number;
      totalPages: number;
    };
  };
};

export type GetWccCardsForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<WccCardState>;
  subscriptionStates?: InputMaybe<
    Array<WccSubscriptionState> | WccSubscriptionState
  >;
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
}>;

export type GetWccCardsForCustomerQuery = {
  __typename?: "RootQueryType";
  wccCardsForCustomer: {
    __typename?: "WccCardPage";
    cards: Array<{
      __typename?: "WccCard";
      archivedAt?: any | null;
      cardNr: string;
      label: string;
      lastChargedAt?: any | null;
      reloadCount?: number | null;
      state: WccCardState;
      subscriptionAmount: string;
      subscriptionState: WccSubscriptionState;
      totalAmountPerPeriod: string;
    }>;
    paginationMetadata: {
      __typename?: "PaginationMetadata";
      page: number;
      pageSize: number;
      totalEntries: number;
      totalPages: number;
    };
  };
};

export type WccCancelCardChargesMutationVariables = Exact<{
  cardNumbers: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  customerId: Scalars["ID"]["input"];
}>;

export type WccCancelCardChargesMutation = {
  __typename?: "RootMutationType";
  wccCancelCardCharges: {
    __typename?: "WccCardMutationResult";
    success: boolean;
    errors: Array<WccCardMutationError>;
    cardResults: Array<{
      __typename?: "WccCardUpdateResult";
      cardNr: string;
      error?: WccCardUpdateResultError | null;
      success: boolean;
    }>;
  };
};

export type GetCustomerManagementCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
}>;

export type GetCustomerManagementCustomerQuery = {
  __typename?: "RootQueryType";
  customerManagementCustomer: {
    __typename?: "CustomerManagementCustomer";
    id: string;
    name: string;
    name2?: string | null;
    customerNumber?: string | null;
    address?: {
      __typename?: "CustomerManagementCustomerAddress";
      street?: string | null;
      city?: string | null;
      postalCode?: string | null;
      country?: string | null;
    } | null;
    serviceSubscriptions: Array<{
      __typename?: "CustomerManagementService";
      id: ServiceSubscription;
      name: string;
      serviceRoles: Array<{
        __typename?: "CustomerManagementServiceRole";
        id: ServiceRole;
        name: string;
      }>;
    }>;
  };
};

export type CustomerManagementInviteUserMutationVariables = Exact<{
  invitationData: InvitationDataInput;
  serviceRoles: Array<ServiceRole> | ServiceRole;
}>;

export type CustomerManagementInviteUserMutation = {
  __typename?: "RootMutationType";
  customerManagementInviteUser: {
    __typename?: "InviteUserMutationResult";
    success: boolean;
    errors: Array<InviteUserMutationError>;
    invitationResult?: InvitationResult | null;
    caseNumber?: string | null;
  };
};

export type CustomerManagementUpdateUserPermissionsMutationVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
  serviceRoles: Array<ServiceRole> | ServiceRole;
}>;

export type CustomerManagementUpdateUserPermissionsMutation = {
  __typename?: "RootMutationType";
  customerManagementUpdateUserPermissions: {
    __typename?: "CustomerManagementUpdateUserPermissionsResult";
    success: boolean;
    errors: Array<CustomerManagementUpdateUserPermissionsError>;
  };
};

export type GetCustomerManagementUserDataForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  states?: InputMaybe<
    Array<CustomerManagementUserState> | CustomerManagementUserState
  >;
  services?: InputMaybe<Array<ServiceSubscription> | ServiceSubscription>;
}>;

export type GetCustomerManagementUserDataForCustomerQuery = {
  __typename?: "RootQueryType";
  customerManagementUserDataForCustomer: {
    __typename?: "CustomerManagementUserDataPage";
    userData: Array<{
      __typename?: "CustomerManagementUserData";
      id: string;
      state: CustomerManagementUserState;
      salutation?: Salutation | null;
      firstName?: string | null;
      email: string;
      lastName: string;
      registrationLink?: string | null;
      createdAt: any;
      servicesWithRoles: Array<{
        __typename?: "CustomerManagementService";
        id: ServiceSubscription;
        name: string;
        serviceRoles: Array<{
          __typename?: "CustomerManagementServiceRole";
          id: ServiceRole;
          name: string;
        }>;
      }>;
    }>;
    paginationMetadata: {
      __typename?: "PaginationMetadata";
      page: number;
      pageSize: number;
      totalEntries: number;
      totalPages: number;
    };
  };
};

export type GetWscCardQueryVariables = Exact<{
  cardNr: Scalars["ID"]["input"];
}>;

export type GetWscCardQuery = {
  __typename?: "RootQueryType";
  wscCard: {
    __typename?: "WscCard";
    acceptanceType: WscCardAcceptanceType;
    additionalText?: string | null;
    bookingText?: string | null;
    cardNr: string;
    decryptedCardPin?: string | null;
    costCenter?: string | null;
    electricityCardEnabled: boolean;
    expiresAt: string;
    gasStationId?: string | null;
    gasStation?: string | null;
    label: string;
    licensePlate?: string | null;
    permissionLevel: WscCardPermissionLevel;
    requestInfoEntry: boolean;
    requestKilometresEntry: boolean;
    state: WscCardState;
    maxTransactionsPerDay: number;
    virtualCardEnabled: boolean;
    futureBlockDate?: any | null;
    electricityCardInformation?: {
      __typename?: "WscCardElectricityCardInformation";
      userAssigned: boolean;
      email?: string | null;
      userInformation?: {
        __typename?: "WscCardElectricityCardUserInformation";
        id: string;
        email?: string | null;
        firstName?: string | null;
        lastName: string;
      } | null;
    } | null;
    virtualCardInformation?: {
      __typename?: "WscCardVirtualCardInformation";
      appIdentification: WscCardVirtualAppIdentification;
      contactType: WscCardVirtualContactType;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      salutation?: Salutation | null;
    } | null;
    alternativeShippingAddress?: {
      __typename?: "WscCardAddress";
      addition?: string | null;
      city: string;
      country: string;
      name: string;
      postalCode: string;
      street: string;
    } | null;
    customer?: {
      __typename?: "WscCustomer";
      id: string;
      usedSalesVolumeInCurrentBillingPeriod: string;
      openSalesVolumeInCurrentBillingPeriod: string;
      salesLimitPerBillingPeriod: string;
      shippingAddress: {
        __typename?: "WscCardAddress";
        addition?: string | null;
        city: string;
        country: string;
        name: string;
        postalCode: string;
        street: string;
      };
    } | null;
  };
};

export type GetWscCardsForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  cardStates?: InputMaybe<Array<WscCardState> | WscCardState>;
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
}>;

export type GetWscCardsForCustomerQuery = {
  __typename?: "RootQueryType";
  wscCardsForCustomer: {
    __typename?: "WscCardPage";
    downloadLink: string;
    cards: Array<{
      __typename?: "WscCard";
      acceptanceType: WscCardAcceptanceType;
      additionalText?: string | null;
      bookingText?: string | null;
      cardNr: string;
      costCenter?: string | null;
      electricityCardEnabled: boolean;
      expiresAt: string;
      gasStation?: string | null;
      label: string;
      licensePlate?: string | null;
      permissionLevel: WscCardPermissionLevel;
      requestInfoEntry: boolean;
      requestKilometresEntry: boolean;
      state: WscCardState;
      maxTransactionsPerDay: number;
      virtualCardEnabled: boolean;
      electricityCardInformation?: {
        __typename?: "WscCardElectricityCardInformation";
        userAssigned: boolean;
        userInformation?: {
          __typename?: "WscCardElectricityCardUserInformation";
          id: string;
          email?: string | null;
          firstName?: string | null;
          lastName: string;
        } | null;
      } | null;
      virtualCardInformation?: {
        __typename?: "WscCardVirtualCardInformation";
        appIdentification: WscCardVirtualAppIdentification;
        contactType: WscCardVirtualContactType;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        salutation?: Salutation | null;
      } | null;
    }>;
    paginationMetadata: {
      __typename?: "PaginationMetadata";
      page: number;
      pageSize: number;
      totalEntries: number;
      totalPages: number;
    };
  };
};

export type WscDeactivatedCardMutationVariables = Exact<{
  cardNr: Scalars["ID"]["input"];
  futureBlockDate?: InputMaybe<Scalars["Date"]["input"]>;
}>;

export type WscDeactivatedCardMutation = {
  __typename?: "RootMutationType";
  wscDeactivateCard: {
    __typename?: "WscMutationResult";
    success: boolean;
    errors: Array<WscMutationError>;
  };
};

export type WscPlaceCardOrderMutationVariables = Exact<{
  orderData: WscOrderDataInput;
}>;

export type WscPlaceCardOrderMutation = {
  __typename?: "RootMutationType";
  wscPlaceCardOrder: {
    __typename?: "WscCardMutationResult";
    success: boolean;
    errors: Array<WscCardMutationError>;
  };
};

export type WscReplaceCardMutationVariables = Exact<{
  cardNr: Scalars["ID"]["input"];
  replacementType: WscReplacementType;
  alternativeShippingAddress?: InputMaybe<WscAddressInput>;
}>;

export type WscReplaceCardMutation = {
  __typename?: "RootMutationType";
  wscPlaceReplacementCardOrder: {
    __typename?: "WscMutationResult";
    success: boolean;
    errors: Array<WscMutationError>;
  };
};

export type WscRevokeFutureCardDeactivationMutationVariables = Exact<{
  cardNr: Scalars["ID"]["input"];
}>;

export type WscRevokeFutureCardDeactivationMutation = {
  __typename?: "RootMutationType";
  wscRevokeFutureCardDeactivation: {
    __typename?: "WscMutationResult";
    success: boolean;
    errors: Array<WscMutationError>;
  };
};

export type WscSendVirtualCardActivationLinkMutationVariables = Exact<{
  cardNr: Scalars["ID"]["input"];
}>;

export type WscSendVirtualCardActivationLinkMutation = {
  __typename?: "RootMutationType";
  wscSendVirtualCardActivationLink: {
    __typename?: "WscSendVirtualCardActivationLinkMutationResult";
    success: boolean;
    errors: Array<WscSendVirtualCardActivationLinkMutationError>;
  };
};

export type GetWscStationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWscStationsQuery = {
  __typename?: "RootQueryType";
  wscStations: Array<{ __typename?: "WscStation"; id: string; label: string }>;
};

export type WscUpdateCardMutationVariables = Exact<{
  cardNr: Scalars["ID"]["input"];
  updateCardData: WscCardUpdateInput;
}>;

export type WscUpdateCardMutation = {
  __typename?: "RootMutationType";
  wscUpdateCard: {
    __typename?: "WscCardMutationResult";
    success: boolean;
    errors: Array<WscCardMutationError>;
  };
};

export type GetWscInvoiceQueryVariables = Exact<{
  invoiceId: Scalars["ID"]["input"];
}>;

export type GetWscInvoiceQuery = {
  __typename?: "RootQueryType";
  wscInvoice: {
    __typename?: "WscInvoice";
    id: string;
    invoicedAt: any;
    grossValue: string;
    documentAvailable: boolean;
    url?: string | null;
  };
};

export type GetWscInvoicesForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  dateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  dateTo?: InputMaybe<Scalars["Date"]["input"]>;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
}>;

export type GetWscInvoicesForCustomerQuery = {
  __typename?: "RootQueryType";
  wscInvoicesForCustomer: {
    __typename?: "WscInvoicePage";
    invoices: Array<{
      __typename?: "WscInvoice";
      id: string;
      invoicedAt: any;
      grossValue: string;
      documentAvailable: boolean;
      url?: string | null;
    }>;
    paginationMetadata: {
      __typename?: "PaginationMetadata";
      page: number;
      pageSize: number;
      totalEntries: number;
      totalPages: number;
    };
  };
};

export type GetWscCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
}>;

export type GetWscCustomerQuery = {
  __typename?: "RootQueryType";
  wscCustomer: {
    __typename?: "WscCustomer";
    id: string;
    usedSalesVolumeInCurrentBillingPeriod: string;
    openSalesVolumeInCurrentBillingPeriod: string;
    salesLimitPerBillingPeriod: string;
    shippingAddress: {
      __typename?: "WscCardAddress";
      addition?: string | null;
      city: string;
      country: string;
      name: string;
      postalCode: string;
      street: string;
    };
  };
};

export type GetWscTransactionQueryVariables = Exact<{
  transactionId: Scalars["ID"]["input"];
}>;

export type GetWscTransactionQuery = {
  __typename?: "RootQueryType";
  wscTransaction: {
    __typename?: "WscTransaction";
    receiptNr: string;
    createdAt: any;
    gasStation: string;
    street: string;
    postalCode: string;
    city: string;
    goodsGroup: string;
    quantity: string;
    unit: string;
    currency: string;
    grossValue: string;
    mileage?: number | null;
    info?: string | null;
    card?: {
      __typename?: "WscCard";
      acceptanceType: WscCardAcceptanceType;
      additionalText?: string | null;
      bookingText?: string | null;
      cardNr: string;
      costCenter?: string | null;
      decryptedCardPin?: string | null;
      electricityCardEnabled: boolean;
      expiresAt: string;
      gasStation?: string | null;
      label: string;
      licensePlate?: string | null;
      permissionLevel: WscCardPermissionLevel;
      requestInfoEntry: boolean;
      requestKilometresEntry: boolean;
      state: WscCardState;
      maxTransactionsPerDay: number;
      virtualCardEnabled: boolean;
      virtualCardInformation?: {
        __typename?: "WscCardVirtualCardInformation";
        appIdentification: WscCardVirtualAppIdentification;
        contactType: WscCardVirtualContactType;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        salutation?: Salutation | null;
      } | null;
      alternativeShippingAddress?: {
        __typename?: "WscCardAddress";
        addition?: string | null;
        city: string;
        country: string;
        name: string;
        postalCode: string;
        street: string;
      } | null;
    } | null;
  };
};

export type GetWscGoodsGroupsQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
}>;

export type GetWscGoodsGroupsQuery = {
  __typename?: "RootQueryType";
  wscGoodsGroups: Array<string>;
};

export type GetWscTransactionsForCustomerQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  dateFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  goodsGroups?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  page: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
}>;

export type GetWscTransactionsForCustomerQuery = {
  __typename?: "RootQueryType";
  wscTransactionsForCustomer: {
    __typename?: "WscTransactionPage";
    downloadLink: string;
    transactions: Array<{
      __typename?: "WscTransaction";
      id: string;
      createdAt: any;
      gasStation: string;
      street: string;
      postalCode: string;
      city: string;
      quantity: string;
      goodsGroup: string;
      grossValue: string;
      currency: string;
      unit: string;
      transactionCardData: {
        __typename?: "WscTransactionCardData";
        cardNr: string;
        label: string;
      };
    }>;
    paginationMetadata: {
      __typename?: "PaginationMetadata";
      page: number;
      pageSize: number;
      totalEntries: number;
      totalPages: number;
    };
  };
};

export type CreateSupportCaseMutationVariables = Exact<{
  data: CreateSupportCaseInput;
}>;

export type CreateSupportCaseMutation = {
  __typename?: "RootMutationType";
  createSupportCase: {
    __typename?: "CreateSupportCaseMutationResult";
    caseNumber?: string | null;
    success: boolean;
    errors: Array<CreateSupportCaseMutationError>;
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: "RootQueryType";
  currentUserV2: {
    __typename?: "CurrentUserQueryResult";
    success: boolean;
    errors: Array<CurrentUserQueryError>;
    user?: {
      __typename?: "User";
      activationState?: string | null;
      email: string;
      salutation: Salutation;
      firstName?: string | null;
      lastName: string;
      addressDetails?: {
        __typename?: "AddressDetails";
        street: string;
        houseNumber: string;
        postalCode: string;
        city: string;
        country: string;
      } | null;
      bankDetails?: {
        __typename?: "BankDetails";
        accountOwner: string;
        iban: string;
        bic?: string | null;
      } | null;
      permissions: {
        __typename?: "Permissions";
        fleetAtHomeUser: boolean;
        fleetAtHomeUserCustomerId?: string | null;
        fleetAtHomeManager: boolean;
        fleetAtHomeManagerCustomerIds: Array<string>;
        wccManager: boolean;
        wccManagerCustomerIds: Array<string>;
        wscManager: boolean;
        wscManagerCustomerIds: Array<string>;
        thgQuoteManager: boolean;
        thgQuoteManagerCustomerIds: Array<string>;
        virtaUser: boolean;
        virtaUserCustomerIds: Array<string>;
        gaasUser: boolean;
        gaasUserCustomerIds: Array<string>;
        westfalenIdAccountManager: boolean;
        westfalenIdAccountManagerCustomerIds: Array<string>;
        customers: Array<{
          __typename?: "Customer";
          id: string;
          name: string;
          name2?: string | null;
          customerNumber?: string | null;
          serviceSubscriptions: Array<ServiceSubscription>;
        }>;
      };
      capabilities: {
        __typename?: "UserCapabilities";
        canStoreAddressDetails: boolean;
        canStoreBankDetails: boolean;
      };
    } | null;
  };
};

export type GetSupportCaseCategorySelectOptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSupportCaseCategorySelectOptionsQuery = {
  __typename?: "RootQueryType";
  supportCaseCategorySelectOptions: Array<{
    __typename?: "SelectOption";
    label: string;
    value: string;
  }>;
};

export type GetActiveNotificationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActiveNotificationQuery = {
  __typename?: "RootQueryType";
  activeNotification?: {
    __typename?: "Notification";
    dateFrom: any;
    dateTo?: any | null;
    type: NotificationType;
    infoText?: string | null;
  } | null;
};

export type ThgQuoteVehicleClassesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ThgQuoteVehicleClassesQuery = {
  __typename?: "RootQueryType";
  thgQuoteVehicleClasses: Array<{
    __typename?: "ThgQuoteVehicleClassInfo";
    vehicleClass: string;
    description: string;
    price?: string | null;
    currency?: string | null;
  }>;
};

export type ThgQuotePendingVehicleRegistrationQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ThgQuotePendingVehicleRegistrationQuery = {
  __typename?: "RootQueryType";
  thgQuotePendingVehicleRegistration?: {
    __typename?: "ThgQuotePendingVehicleRegistration";
    externalId: string;
    vehicleClass: string;
    vehicleAlias?: string | null;
    vehicleCertificateFrontImageId: string;
  } | null;
};

export const UpdateBankDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateBankDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "accountOwner" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "iban" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "bic" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateBankDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "accountOwner" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "accountOwner" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "iban" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "iban" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "bic" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bic" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBankDetailsMutation,
  UpdateBankDetailsMutationVariables
>;
export const UpdateEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdatePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdatePassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "password" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;
export const UpdateUserDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUserData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UserDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUserData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userData" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userData" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserDataMutation,
  UpdateUserDataMutationVariables
>;
export const DeleteMyUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteMyUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteMyUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMyUserMutation,
  DeleteMyUserMutationVariables
>;
export const FinishRegistrationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FinishRegistration" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AddressInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finishRegistration" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "address" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinishRegistrationMutation,
  FinishRegistrationMutationVariables
>;
export const GetFleetAtHomeTransactionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFleetAtHomeTransactions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "creditState" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "energyAmountKilowatthours" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startTime" } },
                { kind: "Field", name: { kind: "Name", value: "stopTime" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFleetAtHomeTransactionsQuery,
  GetFleetAtHomeTransactionsQueryVariables
>;
export const GetFleetAtHomeCreditNotesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFleetAtHomeCreditNotes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeCreditNotes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dateFrom" } },
                { kind: "Field", name: { kind: "Name", value: "dateTo" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "energyAmountKilowatthours" },
                },
                { kind: "Field", name: { kind: "Name", value: "forecasted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "grossAmountInEuro" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "numberOfTransactions" },
                },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFleetAtHomeCreditNotesQuery,
  GetFleetAtHomeCreditNotesQueryVariables
>;
export const GetFleetAtHomeForecastedCreditNotesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFleetAtHomeForecastedCreditNotes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeForecastedCreditNotes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dateFrom" } },
                { kind: "Field", name: { kind: "Name", value: "dateTo" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "energyAmountKilowatthours" },
                },
                { kind: "Field", name: { kind: "Name", value: "forecasted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "grossAmountInEuro" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "numberOfTransactions" },
                },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFleetAtHomeForecastedCreditNotesQuery,
  GetFleetAtHomeForecastedCreditNotesQueryVariables
>;
export const FleetAtHomeShopProductCategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FleetAtHomeShopProductCategories" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeShopProductCategories" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeShopProductCategoriesQuery,
  FleetAtHomeShopProductCategoriesQueryVariables
>;
export const FleetAtHomeShopProductsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FleetAtHomeShopProducts" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeShopProducts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "detailedDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "listPrice" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "category" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimeType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeShopProductsQuery,
  FleetAtHomeShopProductsQueryVariables
>;
export const FleetAtHomeOrderPreviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FleetAtHomeOrderPreview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "FleetAtHomeOrderPreviewDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeOrderPreview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
                { kind: "Field", name: { kind: "Name", value: "netAmount" } },
                { kind: "Field", name: { kind: "Name", value: "shipping" } },
                { kind: "Field", name: { kind: "Name", value: "vatAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeOrderPreviewQuery,
  FleetAtHomeOrderPreviewQueryVariables
>;
export const FleetAtHomePlaceOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FleetAtHomePlaceOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "FleetAtHomeOrderInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomePlaceOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomePlaceOrderMutation,
  FleetAtHomePlaceOrderMutationVariables
>;
export const FleetAtHomeShopProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FleetAtHomeShopProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeShopProduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "detailedDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "listPrice" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "category" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimeType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "configurationOptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shortDescription" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listPrice" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "obligatoryConfigurationOptions",
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shortDescription" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listPrice" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "accessories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shortDescription" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listPrice" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeShopProductQuery,
  FleetAtHomeShopProductQueryVariables
>;
export const FleetAtHomeProposeElectricityPriceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FleetAtHomeProposeElectricityPrice" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "electricityPricePerKilowattHour" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Decimal" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "stationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "electricityPriceCertificateId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeProposeElectricityPrice" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateFrom" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateFrom" },
                },
              },
              {
                kind: "Argument",
                name: {
                  kind: "Name",
                  value: "electricityPricePerKilowattHour",
                },
                value: {
                  kind: "Variable",
                  name: {
                    kind: "Name",
                    value: "electricityPricePerKilowattHour",
                  },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "stationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "stationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "electricityPriceCertificateId" },
                value: {
                  kind: "Variable",
                  name: {
                    kind: "Name",
                    value: "electricityPriceCertificateId",
                  },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeProposeElectricityPriceMutation,
  FleetAtHomeProposeElectricityPriceMutationVariables
>;
export const FleetAtHomeUpdateStationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FleetAtHomeUpdateStation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "stationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "alias" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "street" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "houseNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "postalCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "country" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeUpdateStation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "stationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "stationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "alias" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "alias" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "street" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "street" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "houseNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "houseNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "city" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "city" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "postalCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "postalCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "country" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "country" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeUpdateStationMutation,
  FleetAtHomeUpdateStationMutationVariables
>;
export const FleetAtHomeWithdrawElectricityPriceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FleetAtHomeWithdrawElectricityPrice" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "stationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "fleetAtHomeWithdrawProposedElectricityPrice",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "stationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "stationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeWithdrawElectricityPriceMutation,
  FleetAtHomeWithdrawElectricityPriceMutationVariables
>;
export const GetFleetAtHomeElectricityPriceHistoryForStationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "GetFleetAtHomeElectricityPriceHistoryForStation",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "stationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "fleetAtHomeElectricityPriceHistoryForStation",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "stationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "dateFrom" } },
                { kind: "Field", name: { kind: "Name", value: "dateTo" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "electricityPricePerKilowattHour",
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                { kind: "Field", name: { kind: "Name", value: "reason" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFleetAtHomeElectricityPriceHistoryForStationQuery,
  GetFleetAtHomeElectricityPriceHistoryForStationQueryVariables
>;
export const GetFleetAtHomeStationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFleetAtHomeStations" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeStations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "setupNecessary" },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "alias" } },
                { kind: "Field", name: { kind: "Name", value: "street" } },
                { kind: "Field", name: { kind: "Name", value: "houseNumber" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
                { kind: "Field", name: { kind: "Name", value: "country" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "earliestPossibleElectricityPriceProposal",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentElectricityPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "electricityPricePerKilowattHour",
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "proposedElectricityPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "electricityPricePerKilowattHour",
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "order" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "netAmount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trackingNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vatAmount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFleetAtHomeStationsQuery,
  GetFleetAtHomeStationsQueryVariables
>;
export const FleetAtHomeAcceptProposedElectricityPriceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "FleetAtHomeAcceptProposedElectricityPrice",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "stationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "fleetAtHomeAcceptProposedElectricityPrice",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "stationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "stationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeAcceptProposedElectricityPriceMutation,
  FleetAtHomeAcceptProposedElectricityPriceMutationVariables
>;
export const FleetAtHomeAssignWscToUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FleetAtHomeAssignWscToUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNr" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeAssignWscToUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNr" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNr" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeAssignWscToUserMutation,
  FleetAtHomeAssignWscToUserMutationVariables
>;
export const FleetAtHomeDeclineProposedElectricityPriceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "FleetAtHomeDeclineProposedElectricityPrice",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "stationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reason" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "fleetAtHomeDeclineProposedElectricityPrice",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "stationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "stationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "reason" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reason" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeDeclineProposedElectricityPriceMutation,
  FleetAtHomeDeclineProposedElectricityPriceMutationVariables
>;
export const FleetAtHomeReleaseWscFromUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FleetAtHomeReleaseWscFromUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeReleaseWscFromUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeReleaseWscFromUserMutation,
  FleetAtHomeReleaseWscFromUserMutationVariables
>;
export const GetFleetAtHomeFreeWscsForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFleetAtHomeFreeWscsForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeFreeWscsForCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "cardNr" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFleetAtHomeFreeWscsForCustomerQuery,
  GetFleetAtHomeFreeWscsForCustomerQueryVariables
>;
export const GetFleetAtHomeUserDataForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFleetAtHomeUserDataForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeUserDataForCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "registrationLink" },
                },
                { kind: "Field", name: { kind: "Name", value: "wscNumber" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "stations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "houseNumber" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "currentElectricityPrice",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "electricityPricePerKilowattHour",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dateTo" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "proposedElectricityPrice",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "electricityPricePerKilowattHour",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dateFrom" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFleetAtHomeUserDataForCustomerQuery,
  GetFleetAtHomeUserDataForCustomerQueryVariables
>;
export const FleetAtHomeInviteUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FleetAtHomeInviteUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invitationData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "InvitationDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fleetAtHomeInviteUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "invitationData" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invitationData" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "caseNumber" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FleetAtHomeInviteUserMutation,
  FleetAtHomeInviteUserMutationVariables
>;
export const GetActiveMaintenanceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetActiveMaintenance" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "activeMaintenance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dateFrom" } },
                { kind: "Field", name: { kind: "Name", value: "dateTo" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActiveMaintenanceQuery,
  GetActiveMaintenanceQueryVariables
>;
export const RegisterExistingCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RegisterExistingCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "registrationData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RegisterExistingCustomerInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerExistingCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "registrationData" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "caseNumber" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterExistingCustomerMutation,
  RegisterExistingCustomerMutationVariables
>;
export const GetRegistrationDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetRegistrationData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "provisioningKey" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registrationData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "provisioningKey" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "provisioningKey" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "registrationData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salutation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRegistrationDataQuery,
  GetRegistrationDataQueryVariables
>;
export const RegisterFleetAtHomeUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RegisterFleetAtHomeUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "registrationData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "FleetAtHomeRegistrationDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerFleetAtHomeUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "registrationData" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "registrationData" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterFleetAtHomeUserMutation,
  RegisterFleetAtHomeUserMutationVariables
>;
export const RegisterUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RegisterUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "registrationData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RegistrationDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "registrationData" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "registrationData" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const VerifyEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "VerifyEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const ThgQuotePreregisterVehicleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ThgQuotePreregisterVehicle" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "ThgQuotePendingVehicleRegistrationInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "thgQuotePreregisterVehicle" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pendingVehicleRegistrationId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ThgQuotePreregisterVehicleMutation,
  ThgQuotePreregisterVehicleMutationVariables
>;
export const GetThgQuoteVehicleRegistrationsForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "GetThgQuoteVehicleRegistrationsForCustomer",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "thgQuoteVehicleRegistrationsForCustomer",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vehicleAlias" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vehicleClass" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vehicleOwner" },
                },
                { kind: "Field", name: { kind: "Name", value: "year" } },
                { kind: "Field", name: { kind: "Name", value: "fin" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creationDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validForResubmission" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetThgQuoteVehicleRegistrationsForCustomerQuery,
  GetThgQuoteVehicleRegistrationsForCustomerQueryVariables
>;
export const ThgQuoteResubmitVehicleRegistrationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ThgQuoteResubmitVehicleRegistrations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "applicationIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "thgQuoteResubmitVehicleRegistrations",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ids" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "applicationIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "results" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ThgQuoteResubmitVehicleRegistrationsMutation,
  ThgQuoteResubmitVehicleRegistrationsMutationVariables
>;
export const ThgQuoteDeletePendingVehicleRegistrationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ThgQuoteDeletePendingVehicleRegistration" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "thgQuoteDeletePendingVehicleRegistration",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ThgQuoteDeletePendingVehicleRegistrationMutation,
  ThgQuoteDeletePendingVehicleRegistrationMutationVariables
>;
export const ThgQuoteRegisterVehicleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ThgQuoteRegisterVehicle" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ThgQuoteVehicleRegistrationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "thgQuoteRegisterVehicle" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ThgQuoteRegisterVehicleMutation,
  ThgQuoteRegisterVehicleMutationVariables
>;
export const ThgQuoteVehicleRegistrationInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ThgQuoteVehicleRegistrationInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "thgQuoteVehicleRegistrationInfo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "addressDetailsNecessary" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "vatIdentificationNumberNecessary",
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ThgQuoteVehicleRegistrationInfoQuery,
  ThgQuoteVehicleRegistrationInfoQueryVariables
>;
export const WccReactivateCardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WccReactivateCards" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNumbers" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccReactivateCards" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNumbers" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNumbers" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WccReactivateCardsMutation,
  WccReactivateCardsMutationVariables
>;
export const ActivateCardSubscriptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "activateCardSubscriptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardSubscriptions" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "WccCardSubscriptionInput" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccActivateCardSubscriptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardSubscriptions" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardSubscriptions" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateCardSubscriptionsMutation,
  ActivateCardSubscriptionsMutationVariables
>;
export const WccChargeCardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WccChargeCards" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardCharges" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "WccCardChargeInput" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccChargeCards" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardCharges" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardCharges" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WccChargeCardsMutation,
  WccChargeCardsMutationVariables
>;
export const GetWccChargeHistoryForCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWccChargeHistoryForCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNr" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccChargeHistoryForCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNr" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNr" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "charges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "chargedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isCancelable" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationMetadata" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEntries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWccChargeHistoryForCardQuery,
  GetWccChargeHistoryForCardQueryVariables
>;
export const DeactivateCardSubscriptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deactivateCardSubscriptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNumbers" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccDeactivateCardSubscriptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNumbers" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNumbers" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeactivateCardSubscriptionsMutation,
  DeactivateCardSubscriptionsMutationVariables
>;
export const WccDeactivateCardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WccDeactivateCards" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNumbers" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccDeactivateCards" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNumbers" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNumbers" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WccDeactivateCardsMutation,
  WccDeactivateCardsMutationVariables
>;
export const WccPlaceCardOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WccPlaceCardOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WccOrderDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccPlaceCardOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderData" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderData" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WccPlaceCardOrderMutation,
  WccPlaceCardOrderMutationVariables
>;
export const WccUpdateCardLabelsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WccUpdateCardLabels" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardLabels" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "WccCardLabelInput" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccUpdateCardLabels" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardLabels" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardLabels" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WccUpdateCardLabelsMutation,
  WccUpdateCardLabelsMutationVariables
>;
export const WccUpdateCardSubscriptionAmountsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WccUpdateCardSubscriptionAmounts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardSubscriptions" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "WccCardSubscriptionInput" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccUpdateCardSubscriptionAmounts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardSubscriptions" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardSubscriptions" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WccUpdateCardSubscriptionAmountsMutation,
  WccUpdateCardSubscriptionAmountsMutationVariables
>;
export const GetWccChargeHistoryForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWccChargeHistoryForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "types" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "WccChargeType" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "amountFrom" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Decimal" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "amountTo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Decimal" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccChargeHistoryForCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchString" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchString" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateFrom" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateTo" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "types" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "types" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "amountFrom" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "amountFrom" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "amountTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "amountTo" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "charges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "chargedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isCancelable" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadLink" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationMetadata" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEntries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWccChargeHistoryForCustomerQuery,
  GetWccChargeHistoryForCustomerQueryVariables
>;
export const GetWccInvoiceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWccInvoice" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoiceId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccInvoice" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoiceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoiceId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "invoicedAt" } },
                { kind: "Field", name: { kind: "Name", value: "netValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documentAvailable" },
                },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWccInvoiceQuery, GetWccInvoiceQueryVariables>;
export const GetWccInvoicesForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWccInvoicesForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccInvoicesForCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateFrom" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateTo" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "invoices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoicedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "netValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "documentAvailable" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationMetadata" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEntries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWccInvoicesForCustomerQuery,
  GetWccInvoicesForCustomerQueryVariables
>;
export const GetWccOrdersForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWccOrdersForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DateTime" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DateTime" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "states" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "WccOrderState" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccOrdersForCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchString" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchString" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateFrom" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateTo" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "states" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "states" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderNumber" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "batch1" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardQuantity" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "subscriptionAmount",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "batch2" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardQuantity" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "subscriptionAmount",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "batch3" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardQuantity" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "subscriptionAmount",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationMetadata" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEntries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWccOrdersForCustomerQuery,
  GetWccOrdersForCustomerQueryVariables
>;
export const GetWccCardsForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWccCardsForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "state" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WccCardState" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "subscriptionStates" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "WccSubscriptionState" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccCardsForCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchString" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchString" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "subscriptionStates" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "subscriptionStates" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "state" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "state" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "archivedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastChargedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reloadCount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subscriptionAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subscriptionState" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalAmountPerPeriod" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationMetadata" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEntries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWccCardsForCustomerQuery,
  GetWccCardsForCustomerQueryVariables
>;
export const WccCancelCardChargesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WccCancelCardCharges" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNumbers" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wccCancelCardCharges" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNumbers" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNumbers" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WccCancelCardChargesMutation,
  WccCancelCardChargesMutationVariables
>;
export const GetCustomerManagementCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCustomerManagementCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "customerManagementCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "name2" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customerNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceSubscriptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceRoles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomerManagementCustomerQuery,
  GetCustomerManagementCustomerQueryVariables
>;
export const CustomerManagementInviteUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CustomerManagementInviteUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invitationData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "InvitationDataInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "serviceRoles" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ServiceRole" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "customerManagementInviteUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "invitationData" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invitationData" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "serviceRoles" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "serviceRoles" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "invitationResult" },
                },
                { kind: "Field", name: { kind: "Name", value: "caseNumber" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerManagementInviteUserMutation,
  CustomerManagementInviteUserMutationVariables
>;
export const CustomerManagementUpdateUserPermissionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CustomerManagementUpdateUserPermissions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "serviceRoles" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ServiceRole" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "customerManagementUpdateUserPermissions",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "serviceRoles" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "serviceRoles" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerManagementUpdateUserPermissionsMutation,
  CustomerManagementUpdateUserPermissionsMutationVariables
>;
export const GetCustomerManagementUserDataForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCustomerManagementUserDataForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "states" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "CustomerManagementUserState" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "services" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ServiceSubscription" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "customerManagementUserDataForCustomer",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchString" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchString" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "states" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "states" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "services" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "services" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salutation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "registrationLink" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "servicesWithRoles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "serviceRoles" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationMetadata" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEntries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomerManagementUserDataForCustomerQuery,
  GetCustomerManagementUserDataForCustomerQueryVariables
>;
export const GetWscCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWscCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNr" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNr" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNr" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptanceType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "additionalText" },
                },
                { kind: "Field", name: { kind: "Name", value: "bookingText" } },
                { kind: "Field", name: { kind: "Name", value: "cardNr" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "decryptedCardPin" },
                },
                { kind: "Field", name: { kind: "Name", value: "costCenter" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "electricityCardEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "electricityCardInformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userAssigned" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userInformation" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "gasStationId" },
                },
                { kind: "Field", name: { kind: "Name", value: "gasStation" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "licensePlate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "permissionLevel" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestInfoEntry" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestKilometresEntry" },
                },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "maxTransactionsPerDay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "virtualCardEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "futureBlockDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "virtualCardInformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "appIdentification" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contactType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salutation" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "alternativeShippingAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "addition" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "customer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "usedSalesVolumeInCurrentBillingPeriod",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "openSalesVolumeInCurrentBillingPeriod",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "salesLimitPerBillingPeriod",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingAddress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "addition" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWscCardQuery, GetWscCardQueryVariables>;
export const GetWscCardsForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWscCardsForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardStates" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "WscCardState" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscCardsForCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchString" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchString" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "states" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cardStates" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "acceptanceType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "additionalText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCenter" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "electricityCardEnabled" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "electricityCardInformation",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userAssigned" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userInformation" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiresAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gasStation" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "licensePlate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "permissionLevel" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestInfoEntry" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestKilometresEntry" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "maxTransactionsPerDay" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "virtualCardEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "virtualCardInformation" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "appIdentification",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contactType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phoneNumber" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "salutation" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationMetadata" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEntries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadLink" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWscCardsForCustomerQuery,
  GetWscCardsForCustomerQueryVariables
>;
export const WscDeactivatedCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WscDeactivatedCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNr" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "futureBlockDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscDeactivateCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNr" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNr" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "futureBlockDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "futureBlockDate" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WscDeactivatedCardMutation,
  WscDeactivatedCardMutationVariables
>;
export const WscPlaceCardOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WscPlaceCardOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WscOrderDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscPlaceCardOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderData" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderData" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WscPlaceCardOrderMutation,
  WscPlaceCardOrderMutationVariables
>;
export const WscReplaceCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WscReplaceCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNr" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "replacementType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WscReplacementType" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "alternativeShippingAddress" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WscAddressInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscPlaceReplacementCardOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNr" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNr" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "replacementCardOrderData" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "replacementType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "replacementType" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "alternativeShippingAddress",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "alternativeShippingAddress",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WscReplaceCardMutation,
  WscReplaceCardMutationVariables
>;
export const WscRevokeFutureCardDeactivationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WscRevokeFutureCardDeactivation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNr" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscRevokeFutureCardDeactivation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNr" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNr" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WscRevokeFutureCardDeactivationMutation,
  WscRevokeFutureCardDeactivationMutationVariables
>;
export const WscSendVirtualCardActivationLinkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "wscSendVirtualCardActivationLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNr" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscSendVirtualCardActivationLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNr" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNr" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WscSendVirtualCardActivationLinkMutation,
  WscSendVirtualCardActivationLinkMutationVariables
>;
export const GetWscStationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWscStations" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscStations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWscStationsQuery, GetWscStationsQueryVariables>;
export const WscUpdateCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WscUpdateCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardNr" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "updateCardData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WscCardUpdateInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscUpdateCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardNr" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardNr" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "updateCardData" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "updateCardData" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WscUpdateCardMutation,
  WscUpdateCardMutationVariables
>;
export const GetWscInvoiceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWscInvoice" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoiceId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscInvoice" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoiceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoiceId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "invoicedAt" } },
                { kind: "Field", name: { kind: "Name", value: "grossValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documentAvailable" },
                },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWscInvoiceQuery, GetWscInvoiceQueryVariables>;
export const GetWscInvoicesForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWscInvoicesForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscInvoicesForCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateFrom" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateTo" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchString" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchString" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "invoices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoicedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "grossValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "documentAvailable" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationMetadata" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEntries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWscInvoicesForCustomerQuery,
  GetWscInvoicesForCustomerQueryVariables
>;
export const GetWscCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWscCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "usedSalesVolumeInCurrentBillingPeriod",
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "openSalesVolumeInCurrentBillingPeriod",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "salesLimitPerBillingPeriod" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "addition" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWscCustomerQuery, GetWscCustomerQueryVariables>;
export const GetWscTransactionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWscTransaction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "transactionId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscTransaction" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "transactionId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "transactionId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "receiptNr" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "gasStation" } },
                { kind: "Field", name: { kind: "Name", value: "street" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
                { kind: "Field", name: { kind: "Name", value: "goodsGroup" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "unit" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "grossValue" } },
                { kind: "Field", name: { kind: "Name", value: "mileage" } },
                { kind: "Field", name: { kind: "Name", value: "info" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "card" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "acceptanceType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "additionalText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNr" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCenter" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "decryptedCardPin" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "electricityCardEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiresAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gasStation" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "licensePlate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "permissionLevel" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestInfoEntry" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestKilometresEntry" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "maxTransactionsPerDay" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "virtualCardEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "virtualCardInformation" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "appIdentification",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contactType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phoneNumber" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "salutation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "alternativeShippingAddress",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "addition" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWscTransactionQuery,
  GetWscTransactionQueryVariables
>;
export const GetWscGoodsGroupsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWscGoodsGroups" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscGoodsGroups" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWscGoodsGroupsQuery,
  GetWscGoodsGroupsQueryVariables
>;
export const GetWscTransactionsForCustomerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWscTransactionsForCustomer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customerId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DateTime" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DateTime" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "goodsGroups" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wscTransactionsForCustomer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "customerId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customerId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateFrom" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dateTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateTo" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchString" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchString" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "goodsGroups" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "goodsGroups" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationParams" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionCardData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardNr" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gasStation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "goodsGroup" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "grossValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationMetadata" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalEntries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadLink" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWscTransactionsForCustomerQuery,
  GetWscTransactionsForCustomerQueryVariables
>;
export const CreateSupportCaseDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSupportCase" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateSupportCaseInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSupportCase" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "caseNumber" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSupportCaseMutation,
  CreateSupportCaseMutationVariables
>;
export const GetCurrentUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCurrentUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUserV2" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "activationState" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salutation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "addressDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "street" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "houseNumber" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bankDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accountOwner" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "iban" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "bic" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "permissions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fleetAtHomeUser" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "fleetAtHomeUserCustomerId",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "fleetAtHomeManager",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "fleetAtHomeManagerCustomerIds",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "wccManager" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "wccManagerCustomerIds",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "wscManager" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "wscManagerCustomerIds",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "thgQuoteManager" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "thgQuoteManagerCustomerIds",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "virtaUser" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "virtaUserCustomerIds",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gaasUser" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "gaasUserCustomerIds",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "westfalenIdAccountManager",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "westfalenIdAccountManagerCustomerIds",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customers" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name2" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "customerNumber",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "serviceSubscriptions",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "capabilities" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "canStoreAddressDetails",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "canStoreBankDetails",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetSupportCaseCategorySelectOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSupportCaseCategorySelectOptions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "supportCaseCategorySelectOptions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSupportCaseCategorySelectOptionsQuery,
  GetSupportCaseCategorySelectOptionsQueryVariables
>;
export const GetActiveNotificationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetActiveNotification" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "activeNotification" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dateFrom" } },
                { kind: "Field", name: { kind: "Name", value: "dateTo" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "infoText" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActiveNotificationQuery,
  GetActiveNotificationQueryVariables
>;
export const ThgQuoteVehicleClassesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ThgQuoteVehicleClasses" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "thgQuoteVehicleClasses" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vehicleClass" },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ThgQuoteVehicleClassesQuery,
  ThgQuoteVehicleClassesQueryVariables
>;
export const ThgQuotePendingVehicleRegistrationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ThgQuotePendingVehicleRegistration" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "thgQuotePendingVehicleRegistration" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "externalId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vehicleClass" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vehicleAlias" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "vehicleCertificateFrontImageId",
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ThgQuotePendingVehicleRegistrationQuery,
  ThgQuotePendingVehicleRegistrationQueryVariables
>;
